import { Grid, Typography } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import "./AppItem.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const AppItemMobile = ({
  bannerImg,
  title,
  description,
  appIcon,
  playStoreUrl,
  appStoreUrl,
  cssClassName,
}) => {
  const onOpenLinkInNewTabClick = (link) => {
    window.open(link, "_blank");
  };

  const [isExpand, setIsExpand] = useState(false);

  return (
    <div
      style={{
        width: "100%",
        boxShadow: "0 0 6px 6px #efefef",
        borderRadius: "5%",
      }}
    >
      <img src={bannerImg} alt={title} style={{ width: "100%" }} />

      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ marginTop: "10px", paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Grid item xs={2} md={2}>
          <img src={appIcon} alt={title} style={{ width: "100%" }} />
        </Grid>
        <Grid item sx={{ paddingLeft: "15px" }} xs={10} md={10}>
          <Typography
            gutterBottom
            variant="h6"
            sx={{ marginBottom: "0", color: "black", fontWeight: 600 }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>

      <Typography
        align="justify"
        className={`app-description ${isExpand ? cssClassName : ""}`}
      >
        {description}
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ padding: "10px" }}
      >
        <Grid item sx={{ display: "flex" }}>
          <img
            src="/images/apps/play_store_icon.png"
            alt="Play Store Icon"
            style={{ width: "72%", cursor: "pointer" }}
            onClick={() => onOpenLinkInNewTabClick(playStoreUrl)}
          />
        </Grid>
        {_.isEmpty(appStoreUrl) ? (
          ""
        ) : (
          <Grid item>
            <img
              src="/images/apps/app_store_icon.png"
              alt="App Store Icon"
              style={{ width: "65%", cursor: "pointer" }}
              onClick={() => onOpenLinkInNewTabClick(appStoreUrl)}
            />
          </Grid>
        )}
        {_.isEmpty(cssClassName) ? (
          ""
        ) : (
          <Grid
            item
            sx={{ marginRight: 0, marginLeft: "auto", padding: "10px" }}
            onClick={() => setIsExpand(!isExpand)}
          >
            {isExpand ? <FaAngleUp size="30" /> : <FaAngleDown size="30" />}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AppItemMobile;
