import React, { useState, useEffect } from "react";
import { MenuItems } from "./MenuItems";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./NavBarNew.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

function NavBarNew() {
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const history = useHistory();

  const [submenuOpen, setSubmenuOpen] = useState(false);
  const location = useLocation();

  const handleSubmenuClick = () => {
    setSubmenuOpen(false);
  };

  useEffect(() => {
    var navbar = document.getElementById("navbar");
    window.onscroll = () => {
      if (window.pageYOffset > 0) {
        // console.log("show shadow");
        navbar.classList.add("nav-shadow");
      } else {
        // console.log("remove shadow");
        navbar.classList.remove("nav-shadow");
      }
    };
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        sx={{ my: 2, fontWeight: "bold", color: "#5e5c90" }}
      >
        PlayBees
      </Typography>
      <Divider />
      <List>
        {MenuItems.map((item) => {
          if (item.title !== "Speaker")
            return (
              <ListItem key={item.title} disablePadding sx={{ my: 2 }}>
                <ListItemButton
                  sx={{
                    textAlign: "center",
                  }}
                  onClick={() => {
                    handleDrawerToggle();
                    history.push(item.url);
                  }}
                >
                  <ListItemText
                    primary={item.title}
                    sx={{ fontSize: "1.1rem", color: "#5e5c90" }}
                    disableTypography
                  />
                </ListItemButton>
              </ListItem>
            );
          else return "";
        })}
      </List>
    </Box>
  );

  return (
    <div id="navbar" className="nav-parent" style={{ zIndex: 100 }}>
      <nav className="NavbarItems">
        <Link className="navbar-logo" to="/">
          <img
            src="/images/logos/playbees_logo.png"
            alt="playbees logo"
            className="logo-img"
          />
        </Link>
        <div className="menu-icon">
          <img
            onClick={handleDrawerToggle}
            src="images/menu_icon.png"
            alt="Menu Icon"
            style={{ width: "35%", height: "auto", marginRight: "10%" }}
          />
        </div>
        <div>
          <ul className="web-menu">
            {MenuItems.map((item, index) => {
              return item.title !== "Speaker" ? (
                <li key={index} className={item.cLi}>
                  <Link
                    className={item.cName}
                    to={item.url}
                    style={
                      location.pathname === item.url
                        ? { fontWeight: "500", color: "#e87619" }
                        : { fontWeight: "500" }
                    }
                  >
                    {item.title}
                  </Link>
                </li>
              ) : (
                <li
                  key={index}
                  className={item.cLi}
                  onMouseEnter={() => setSubmenuOpen(true)}
                  onMouseLeave={() => setSubmenuOpen(false)}
                >
                  <span
                    style={
                      submenuOpen ||
                      ["/divine", "/podcast", "/speakertnc"].indexOf(
                        location.pathname
                      ) !== -1
                        ? { fontWeight: "bold", color: "#e87619" }
                        : { fontWeight: "bold", color: "#5e5c90" }
                    }
                  >
                    {item.title}
                  </span>
                  <ul
                    className={`submenu-items ${submenuOpen ? "open" : ""}`}
                    onClick={handleSubmenuClick}
                  >
                    <li className="nav-li" style={{ marginBottom: "10px" }}>
                      <Link className={item.cName} to="/divine">
                        PlayBees Speaker Divine
                      </Link>
                    </li>
                    <li className="nav-li">
                      <Link className={item.cName} to="/podcast">
                        PlayBees Speaker Podcasts
                      </Link>
                    </li>
                  </ul>
                </li>
              );
            })}
          </ul>

          <nav>
            <Drawer
              anchor="right"
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </div>
      </nav>
    </div>
  );
}

export default NavBarNew;
