import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "./../../CommonStyle.css";
import SpeakerSubMenu from "../SpeakerSubMenu";
import { SPEAKER_DIVINE, SUB_MENU_HIGHLIGHT } from "../../../Constants";
import { sendEvent } from "../../../Config/AnalyticsConfig";

const style = {
  topTitle: {
    fontSize: "4.2rem",
    letterSpacing: "0px",
    textAlign: "center",
    fontFamily: `MilkyNiceClean !important`,
    color: "#ff064c",
  },
};

const Divine = () => {
  useEffect(() => {
    sendEvent(`divine_page_load`);
  }, []);

  const onOpenLinkInNewTabClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Container className="page-parent" style={{ padding: "0px" }} maxWidth="xl">
      <Helmet>
        <title>
          FirstCry PlayBees Reviews | Approved by Teachers & Parents
        </title>
        <meta
          name="keywords"
          content="educational, baby songs, baby games, kids games, toddler learning games, story book, read stories, puzzle, memory games, preschool, kindergarten, nursery rhymes"
        />
        <meta
          name="description"
          content="Preschool educational app for kids that has 1000+ toddler learning games, baby songs, nursery rhymes and kindergarten story books for reading children!"
        />
        <meta name="author" content="FirstCry PlayBees" />
      </Helmet>

      <SpeakerSubMenu
        title={SPEAKER_DIVINE}
        textToHighLight={SUB_MENU_HIGHLIGHT.HOME}
      />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: `url(${"images/speaker/rainbow.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Typography sx={{ ...style.topTitle, marginTop: "50px" }}>
          Ultimate Kid's Speaker
        </Typography>
        <Typography sx={{ ...style.topTitle, marginLeft: "120px" }}>
          For Unlimited Learning
        </Typography>
        <img
          src="images/speaker/divine/divine_device.png"
          alt="Approved By Teachers and Parents"
          style={{ width: "45%", height: "45%", marginBottom: "70px" }}
        />
      </Grid>

      <img
        src="images/speaker/red_bg_top_part.png"
        style={{
          position: "absolute",
          width: "100%",
          transform: "translateY(-60%)",
          zIndex: 2,
          maxWidth: "inherit",
        }}
        alt="PlayBees Bird"
      />

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: `url(${"images/speaker/red_bg.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid item>
          <Typography
            sx={{
              fontSize: "3rem",
              letterSpacing: "0px",
              marginTop: "70px",
              textAlign: "center",
              fontFamily: `Jelly !important`,
              color: "white",
            }}
          >
            500+ Contents to Explore
          </Typography>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ paddingBottom: "50px" }}
        >
          <Grid
            item
            textAlign="center"
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "fit-content" }}
          >
            <Grid item>
              <img
                src="images/speaker/nursery_rhymes.png"
                width="auto"
                height="auto"
                alt=""
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginBottom: "0px",
                  color: "white",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Nursery Rhymes
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            textAlign="center"
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "fit-content" }}
          >
            <Grid item>
              <img
                src="images/speaker/stories_and_audio_books.png"
                alt="PlayBees Stories And Audio Books"
              />
            </Grid>
            <Grid item>
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginBottom: "0px",
                  color: "white",
                  fontWeight: "bold",
                  width: "150px",
                  textAlign: "center",
                }}
              >
                Stories & Audio Books
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          sx={{ paddingBottom: "80px" }}
        >
          <Grid
            item
            md={3.5}
            lg={3.5}
            xl={3.5}
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-end"
          >
            <Grid
              item
              sx={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <img
                src="images/speaker/divine/divine_songs_and_stories.png"
                alt=""
              />
              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginBottom: "0px",
                  color: "white",
                  fontWeight: "bold",
                  width: "180px",
                  textAlign: "center",
                }}
              >
                Divine Songs and Stories
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={5} lg={5} xl={5} textAlign="center">
            <img
              src="images/speaker/divine/divine_device_two.png"
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          </Grid>
          <Grid
            item
            lg={3.5}
            xl={3.5}
            md={3.5}
            textAlign="center"
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              sx={{
                display: "grid",
                placeItems: "center",
                marginLeft: "-8%",
                marginTop: "-12%",
              }}
            >
              <img src="images/speaker/divine/early_learning.png" alt="" />

              <Typography
                variant="h5"
                gutterBottom
                style={{
                  marginBottom: "0px",
                  color: "white",
                  fontWeight: "bold",
                  width: "200px",
                  textAlign: "center",
                }}
              >
                Early Learning Content
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <img
        src="images/speaker/red_bg_bottom_part.png"
        style={{
          position: "absolute",
          width: "100%",
          transform: "translateY(-50%)",
          zIndex: 2,
          maxWidth: "inherit",
        }}
        alt="PlayBees Red BG"
      />

      <Container
        maxWidth="xl"
        style={{
          paddingRight: 0,
          position: "absolute",
          transform: "translateY(-65%)",
          zIndex: 3,
          textAlign: "right",
        }}
      >
        <img
          src="images/speaker/bird.png"
          style={{
            width: "15%",
          }}
          alt="PlayBees Bird"
        />
      </Container>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        sx={{
          backgroundImage: `url(${"images/speaker/blue_bg.jpg"})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingTop: "100px",
          paddingBottom: "50px",
        }}
      >
        <Grid
          item
          xl={2}
          lg={2}
          sx={{ marginTop: "70px", paddingLeft: "30px" }}
        >
          <img
            src="images/speaker/lamp.png"
            alt="PlayBees Lamp"
            style={{ width: "65%" }}
          />
        </Grid>

        <Grid
          item
          xl={7}
          lg={7}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item textAlign="center">
            <img
              src="images/logos/playbees_logo_big.png"
              alt="PlayBees Logo"
              style={{ width: "40%" }}
            />
          </Grid>

          <Grid item textAlign="center">
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                fontSize: "2.6rem",
              }}
            >
              FirstCry PlayBees app is Ads free
            </Typography>
            <Typography
              sx={{
                color: "white",
                textAlign: "center",
                fontSize: "2.6rem",
              }}
            >
              for the safety of kids!
            </Typography>
          </Grid>

          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
            sx={{ marginTop: "10px", marginBottom: "40px" }}
          >
            <Grid item>
              <img
                src="images/logos/googleplay_button.png"
                alt="FirstCry PlayBees App for Android - Google PlayStore"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  onOpenLinkInNewTabClick(
                    `https://play.google.com/store/apps/details?id=com.firstcry.playbees.learn.grow.kids.preschool.toddler.numbers.abc.rhymes.games.stories.books`
                  )
                }
              />
            </Grid>
            <Grid item>
              <img
                src="images/logos/appstore_button.png"
                alt="FirstCry PlayBees App for iOS - iPhone and iPad"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  onOpenLinkInNewTabClick(
                    `https://apps.apple.com/us/app/firstcry-playbees-kids-games/id1529402533`
                  )
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xl={3}
          lg={3}
          textAlign="right"
          sx={{ marginTop: "70px", paddingRight: "30px" }}
        >
          <img
            src="images/speaker/lamp.png"
            alt="PlayBees Lamp"
            style={{ width: "40%" }}
          />
        </Grid>
      </Grid>

      <Container
        maxWidth="xl"
        style={{
          position: "absolute",
          zIndex: 3,
          textAlign: "right",
          transform: "translateY(-75%)",
          paddingRight: 0,
        }}
      >
        <img
          src="images/speaker/cat.png"
          style={{
            width: "20%",
          }}
          alt="PlayBees Cat"
        />
      </Container>

      <Container
        maxWidth="xl"
        style={{
          position: "absolute",
          zIndex: 3,
          transform: "translateY(-95%)",
          textAlign: "left",
          paddingLeft: 0,
        }}
      >
        <img
          src="images/speaker/pot.png"
          style={{
            width: "8%",
          }}
          alt="PlayBees Pot"
        />
      </Container>

      <img
        src="images/speaker/white_bg_top_part.png"
        style={{
          position: "absolute",
          width: "100%",
          transform: "translateY(-80%)",
          zIndex: 2,
          maxWidth: "inherit",
        }}
        alt="PlayBees White BG"
      />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: "10px", marginBottom: "30px" }}
      >
        <Grid item lg={9} xl={9}>
          <div>
            <label style={{ color: "#00e4ff", fontSize: "2rem" }}>
              FirstCry
            </label>
            <label
              style={{ marginLeft: "10px", color: "#df0044", fontSize: "2rem" }}
            >
              PlayBees
            </label>
          </div>
          <Typography sx={{ fontSize: "1.1rem" }}>
            Brainbees Solutions Private Limited
          </Typography>
          <Typography sx={{ fontSize: "1.1rem" }}>
            Rajashree Business Park, Plot No 114, Survey No 338, Tadiwala Road,
            Nr. Sohrab Hall, Pune, Maharashtra, India-411001.
          </Typography>
          <Typography sx={{ fontSize: "1.1rem" }}>
            Email: customercare@brainbees.com
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Divine;
