import React, { useEffect } from "react";
import { Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import "./../CommonStyle.css";
import DownloadSection from "../DownloadSection";
import SpeakerSubMenu from "./SpeakerSubMenu";
import { SUB_MENU_HIGHLIGHT } from "../../Constants";
import { useLocation } from "react-router-dom";
import { sendEvent } from "../../Config/AnalyticsConfig";

const SpeakerTNC = () => {
  const location = useLocation();
  const title = location.state?.title;

  useEffect(() => {
    sendEvent(`speaker_tnc_page_load`);
  }, []);

  return (
    <Container className="page-parent" sx={{ color: "#5e5c90" }}>
      <Helmet>
        <title>
          FirstCry PlayBees Reviews | Approved by Teachers & Parents
        </title>
        <meta
          name="keywords"
          content="educational, baby songs, baby games, kids games, toddler learning games, story book, read stories, puzzle, memory games, preschool, kindergarten, nursery rhymes"
        />
        <meta
          name="description"
          content="Preschool educational app for kids that has 1000+ toddler learning games, baby songs, nursery rhymes and kindergarten story books for reading children!"
        />
        <meta name="author" content="FirstCry PlayBees" />
      </Helmet>
      <SpeakerSubMenu title={title} textToHighLight={SUB_MENU_HIGHLIGHT.TNC} />
      <br />
      <Typography variant="body1" gutterBottom align="justify">
        We at PlayBees, make products for kids and toddlers of all age groups.
        Under normal use and service, we warrant to the Buyer / User / Customer
        ("Consumer") of the Product that, for a period of 6 months from the
        invoice date ("Warranty Period"), the Product is free from manufacturing
        defects. The brand will at its sole discretion, repair or replace any
        defective Product or part(s) of the Product during the warranty period.
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom align="justify">
        The device comes with a 6-month warranty on all parts, excluding
        accessories. This covers servicing/ repairs or replacing damaged parts.
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom>
        This limited Warranty <b>ONLY COVERS MANUFACTURING DEFECT</b> AND does
        not cover ANY claims and/or damage ARISING from:
      </Typography>
      <Typography variant="body1" gutterBottom>
        1) Misuse or tampering causes any product data/content loss.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        2) Events outside FirstCry's control; failures due to fire, water
        damage, electrical disturbances; damages resulting from negligence or
        mishandling; usage for commercial, business, industrial, educational, or
        rental purposes.
      </Typography>
      <Typography variant="body1" gutterBottom>
        3) Use manufacturer specified attachments/accessories only.
      </Typography>
      <Typography variant="body1" gutterBottom>
        4) Product Handling:
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ paddingLeft: "25px" }}>
        A) Exposure of the product to excessive dust or heat (under direct
        sunlight, gas burners, heaters).
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ paddingLeft: "25px" }}>
        B) Liquid spills onto the device, as this could cause malfunction or
        could prove to be a fire hazard.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ paddingLeft: "25px" }}>
        C) The device should not be cleaned with a wet cloth.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ paddingLeft: "25px" }}>
        D) Pinching of the cord or walking on it when closer to the plug.
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ paddingLeft: "25px" }}>
        E) Submerging the device in water.
      </Typography>
      <Typography variant="body1" gutterBottom>
        5) Battery Handling: A) Exposing the battery to temperatures above 60°C
        B) Causing mechanical shocks to the battery pack.
      </Typography>
      <Typography variant="body1" gutterBottom>
        6) For any repair/service requirements, contact our customer care
        service.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Email: customercare@brainbees.com/ Contact Number: +91-135-3501241
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Important:
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        The customer must bring the product to the Authorised Dealer at his/her
        sole cost and responsibility, along with the original invoice, as part
        of the carry-in warranty service.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        Disclaimer:
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Only Product specified by PlayBees is covered under this warranty for
        defects in materials and workmanship. The brand shall have the sole
        discretion to determine the kind of defect in the Product and whether
        the Product or the part of the Product is/are eligible for
        repair/replacement or not. As specified in the warranty details, it
        applies exclusively only during the the course of 6 months of purchase,
        and it is only applicable when a defect has arisen wholly or
        substantially, due to faulty manufacturing of the Product. The warranty
        does not cover damage caused by normal handling or wear and tear of the
        Product. Damages caused by the Buyer / User / Customer modifying,
        attempting to repair, or otherwise altering the product will not be
        covered in this warranty. The Brand reserves the rights to change the
        warranty details at any time and without notice. Invoice is required for
        warranty claims.
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom sx={{ fontWeight: "bold" }}>
        WARRANTY COVERS ONLY MANUFACTURING DEFECTS.
      </Typography>
      <DownloadSection />
    </Container>
  );
};

export default SpeakerTNC;
