export const FaqItems = [
  {
    tabTitle: `General`,
    items: [
      {
        question: `Facing Issues with FC Club Membership?`,
        answer: `For Android:<br/>Users can avail free PlayBees membership by logging into the app with the same email ID that was used to purchase FirstCry Club Membership.<br/><br/>For iOS: <br/>1. Please write to us at <b>playbees@firstcry.com</b> with the email ID that you purchased FC Club with <br />2. We will share a coupon code with you to avail PlayBees membership <br />3. The coupon code must be entered within the app's coupon code section <br />4. We will send you the detailed steps to avail Coupon Code <br />`,
      },
      {
        question: `Where can we get FirstCry Club Membership?`,
        answer: `FirstCry Club Membership can be bought from https://www.firstcry.com/. It offers a wide range of benefits and one of the key benefits is a free subscription to PlayBees app.`,
      },
      {
        question: `I am a FirstCry Club Member and I am facing issues while logging in with Password. Why?`,
        answer: `Please note that we are fixing an issue that will impact logins with Password, we suggest you login with OTP.`,
      },
      {
        question: `How to unlock the content for free if you are one of the first 1000 users?`,
        answer: `1. Go to the menu section on the top right of the app lobby <br />2. Click on Login at the bottom of the menu <br />3. Enter your email id or mobile number <br />4. Click continue <br />5. If you are already a FirstCry user, you will be directed to the verification page and then to the app lobby <br />6. If you are a new user, you will have to Register yourself first <br />7. Once you Login/Register you should see the content unlocked, provided if you are one of the first 1000 users`,
      },
      {
        question: `Why should I subscribe?`,
        answer: `Firstcry Playbees offers a variety of content and learning for kids aged from 0-8 through classic rhymes, creatively crafted stories and fun games. Learning has never been so much fun!`,
      },
      {
        question: `I have purchased the subscription on my iPad or iPhone, can I also use the same on my Android device? Or vice versa?`,
        answer: `No, purchases made on either iOS or Android devices will be valid only on devices with the respective OS. One cannot purchase the subscription on one platform and access it on other, if you wish to play on a different OS device, you will have to purchase the subscription again.`,
      },
      {
        question: `There is no sound. What do I do?`,
        answer: `Go to Settings Menu and check if you have the Sound turned off, if not, please check the sound of your device from device settings`,
      },
      {
        question: `Can I set a timer for usage of the app?`,
        answer: `Yes. Go to Settings Menu and select the span of time you'd like the App to be used in a day. Setting the app usage time to 24 hrs inadvertently switches off this feature until you change it.`,
      },
      {
        question: `Can I register mulitple kids for the app?`,
        answer: `Yes. You can register up to 3 kids through one account.`,
      },
      {
        question: `Why is the Kid's Profile Greyed Out?`,
        answer: `If you are seeing one of your kid's profile greyed out, it means you have deleted that profile. Please note it takes up to 48 hrs for a profile to be deleted and removed from your account.`,
      },
      {
        question: `How Can I delete my Kid's profile?`,
        answer: `1. Tap the avatar under the account (hamburger menu) section.<br />2. Select a Kid's profile and then click on Edit.<br /> 3. Click on Delete <br />Please note it takes up to 48 hrs for a profile to be deleted and removed from your account.`,
      },
      {
        question: `Can I subscribe to the app without logging in?`,
        answer: `Yes, you can subscribe to our app and access all the content. But we suggest you login to unlock exclusive features such as Multiple Devices Support.`,
      },
      {
        question: `I am unable to play on multiple devices. Why?`,
        answer: `Please make sure you are logged in to our app to access the Multi device login support. It's exclusive for logged in memebers.`,
      },
      {
        question: `Playstore account is linked, but content seems locked. Why?`,
        answer: `Plase check if you have logged into the app with the email ID you registered with initially. If not, please logout and re-login with the email ID that you've used to register and purchase the subscription.`,
      },
    ],
  },
  {
    tabTitle: `Technical`,
    items: [
      {
        question: `Facing Issues with FC Club Membership?`,
        answer: `For Android:<br/>Users can avail free PlayBees membership by logging into the app with the same email ID that was used to purchase FirstCry Club Membership.<br/><br/>For iOS: <br/>1. Please write to us at <b>playbees@firstcry.com</b> with the email ID that you purchased FC Club with <br />2. We will share a coupon code with you to avail PlayBees membership <br />3. The coupon code must be entered within the app's coupon code section <br />4. We will send you the detailed steps to avail Coupon Code <br />`,
      },
      {
        question: `Where can we get FirstCry Club Membership?`,
        answer: `FirstCry Club Membership can be bought from https://www.firstcry.com/. It offers a wide range of benefits and one of the key benefits is a free subscription to PlayBees app.`,
      },
      {
        question: `The downloading of songs stops in between. What should I do?`,
        answer: `Sometimes, due to connectivity issues, the downloading of songs stops or takes a longer time. Make sure you are connected to wi-fi or faster connection for seamless downloads.`,
      },
      {
        question: `If some file doesn't work after download, what should I do?`,
        answer: `Restart your app and check if you are now able to play the content - if that doesn't work, go to 'Settings' in the app, click on Manage Downloads' and delete the file you are not able to play and then redownload it again.`,
      },
      {
        question: `The app has started lagging or crashing. What should I do?`,
        answer: `For iPhone devices:<br /> Please force close the app with these steps:<br /> a) Press the Home button of your device two times quickly. You'll see small previews of your recently used apps.<br /> b) Swipe up on the app's preview to close it.<br /> c) Start the app once again.<br /> <br /> For Android devices: You may be using the app on a low-end device or the RAM available must be less. You could force close all the other running apps and then open the Rhymes app again and see if performance improves.`,
      },
      {
        question: `I am running out of storage space. What do I do?`,
        answer: `Go to 'Settings' in the app, click on Manage Downloads' and delete the files that you no longer need.`,
      },
      {
        question: `Can I use the app offline?`,
        answer: `Sorry, but PlayBees doesn’t support offline mode. You will need to be connected to the internet at all times.`,
      },
    ],
  },
  {
    tabTitle: `Subscription / In-app Purchase`,
    items: [
      {
        question: `Facing Issues with FC Club Membership?`,
        answer: `For Android:<br/>Users can avail free PlayBees membership by logging into the app with the same email ID that was used to purchase FirstCry Club Membership.<br/><br/>For iOS: <br/>1. Please write to us at <b>playbees@firstcry.com</b> with the email ID that you purchased FC Club with <br />2. We will share a coupon code with you to avail PlayBees membership <br />3. The coupon code must be entered within the app's coupon code section <br />4. We will send you the detailed steps to avail Coupon Code <br />`,
      },
      {
        question: `Where can we get FirstCry Club Membership?`,
        answer: `FirstCry Club Membership can be bought from https://www.firstcry.com/. It offers a wide range of benefits and one of the key benefits is a free subscription to PlayBees app.`,
      },
      {
        question: `I am a FirstCry Club Member and I am facing issues while logging in with Password. Why?`,
        answer: `Please note that we are fixing an issue that will impact logins with Password, we suggest you login with OTP.`,
      },
      {
        question: `I am already a subscriber. What will happen if a I make a new purchase?`,
        answer: `If a make a new purchase despite being a subscriber, your currect pack's validity will be overridden with the new purchased pack's validity.`,
      },
      {
        question: `I am already a subscriber. How do I access my content?`,
        answer: `If you are already a subscriber, please login to the app without making a purchase and you can restore your progress.`,
      },
      {
        question: `How can I upgrade to a Gold/ Platinum Pack?`,
        answer: `Sorry, please note that one cannot upgrade or downgrade a subscription. You can buy the other pack by paying the full amount, but it will override the existing pack’s validity.`,
      },
      {
        question: `My subscription is not auto renewing. What should I do?`,
        answer: `Please note that it's a mandate by the RBI that we cannot auto renew subscription. If you wish to continue after your current packs' validity, please make the purchase again.`,
      },
      {
        question: `How do I Unlock the locked content?`,
        answer: `You can unlock the content by starting a free trial. If you happen to cancel the free trial mid-way - you will have to purchase a subscription, tenure of the unlimited access to the content will be basis on the plan you chose.`,
      },
      {
        question: `Is there a free trial?`,
        answer: `Free trial is currently not available on Android, but is available on the iOS version of the application.`,
      },
      {
        question: `I am using an iOS device. It says “Free Trial”, but my money got deducted, why?`,
        answer: `Sorry, but this seems to be an issue on Apple’s end, if you wish to claim a refund for the subscription, please follow the below steps:
        <ul>
          <li>Go to reportaproblem.apple.com.</li>
          <li>Sign in with your Apple ID and password.</li>
          <li>If you see the "Report" or "Report a Problem" button next to the item that you want to request a refund for, click it.</li>
          <li>Follow the instructions on the page to choose the reason why you want a refund and submit your request.</li>
        </ul>`,
      },
      {
        question: `How do I check my Purchase Order History?`,
        answer: `You can check your order history by logging into your Playstore app. Apple users can check their order history through iTunes Connect.`,
      },
      {
        question: `Can I cancel my purchase? Or Claim a refund?`,
        answer: `Yes, you can do both by following the below steps, please note that refund claims are valid before 48hrs (on Android) and before 7 days (on iOS)
        <br />
        <label style="font-weight: bold">Cancel subscription on Android -</label>
        <ul>
          <li>On your Android phone or tablet, open the Google Play Store.</li>
          <li>Check if you're signed in to the correct Google Account.</li>
          <li>Tap Menu. Subscriptions.</li>
          <li>Select the subscription you want to cancel.</li>
          <li>Tap Cancel subscription.</li>
          <li>Follow the instructions.</li>
        </ul>

        <label style="font-weight: bold">Claim a refund on Android -</label>
        <ul>
          <li>On your computer, go to play.google.com/store/account</li>
          <li>Click Order History</li>
          <li>Find the order you want to return.</li>
          <li>Select Request a refund or report a problem and choose the option that describes your situation.</li>
          <li>Complete the form and note that you'd like a refund.</li>
        </ul>

        <label style="font-weight: bold">Cancel subscription on iOS -</label>
        <ul>
          <li>Open the Settings app.</li>
          <li>Tap your name.</li>
          <li>Tap Subscriptions.</li>
          <li>Tap the subscription that you want to manage. Don't see the subscription that you're looking for?</li>
          <li>Tap Cancel Subscription. (Or if you want to cancel Apple One but keep some subscriptions, tap Choose Individual Services.)</li>
        </ul>

        <label style="font-weight: bold">Claim a refund on iOS -</label>
        <ul>
          <li>Go to reportaproblem.apple.com.</li>
          <li>Sign in with your Apple ID and password.</li>
          <li>If you see the "Report" or "Report a Problem" button next to the item that you want to request a refund for, click it.</li>
          <li>Follow the instructions on the page to choose the reason why you want a refund and submit your request.</li>
        </ul>`,
      },
      {
        question: `Will I have to pay separately again, once new content is added?`,
        answer: `No. Once you have made the purchase from within the app, you will have lifetime access to the app regardless of the content addition or removal.`,
      },
      {
        question: `Will I still have access to the content that I have already downloaded once my trial period is over?`,
        answer: `Yes, you will still have access to the free content that you have downloaded. Paid content will be locked.`,
      },
      {
        question: `I've made the purchase within the app, but the content is still locked. What do I do?`,
        answer: `Please write to us at playbees@firstcry.com detailing your issue along with your registered email ID.`,
      },
    ],
  },
];
