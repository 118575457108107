export const getHomePageCarousalItems = (isMobile = false) => {
  return [
    {
      imgUrl: `/images/home/top_banners/${
        isMobile ? "mobile" : "web"
      }/banner_1.jpg`,
      imgAlt: "Kindergarten Kids Educational Games App",
    },
    {
      imgUrl: `/images/home/top_banners/${
        isMobile ? "mobile" : "web"
      }/banner_2.jpg`,
      imgAlt: "Toddler Learning Games For Homeschooling",
    },
    {
      imgUrl: `/images/home/top_banners/${
        isMobile ? "mobile" : "web"
      }/banner_3.jpg`,
      imgAlt: "Preschool Baby Songs & Nursery Rhymes",
    },
  ];
};

export const getHomePageCuratedContentData = (isMobile = false) => {
  return [
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_early_learning.jpg`,
      imgAlt: "PlayBees Early Learning",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_animal_day_care.jpg`,
      imgAlt: "PlayBees Animal Day Care",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_bake_a_cake.jpg`,
      imgAlt: "PlayBees Bake A Cake",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_fashion_games.jpg`,
      imgAlt: "PlayBees Fashion Game",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_ice_cream_truck.jpg`,
      imgAlt: "PlayBees Ice Cream Truck",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_juice_it_up.jpg`,
      imgAlt: "PlayBees Juice It Up",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_musical_games.jpg`,
      imgAlt: "PlayBees Musical Games",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_new_games_launched.jpg`,
      imgAlt: "PlayBees New Games",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_real_painting.jpg`,
      imgAlt: "PlayBees Real Painting",
    },
    {
      imgUrl: `/images/home/curated_contents/${
        isMobile ? "mobile" : "web"
      }/curated_content_time_training.jpg`,
      imgAlt: "PlayBees Time Training",
    },
  ];
};

export const HOME_PAGE_APP_FEATURES = [
  {
    title: "Parental Locks",
    discription: "Easily manage your child's profile and screen-time",
    imgUrl: "",
    imgAlt: "Parental Locks",
  },
  {
    title: "Kids Skills Report",
    discription: "Monitory your kids progress in detail",
    imgUrl: "",
    imgAlt: "Kids Skills Report",
  },
  {
    title: "No ADS",
    discription: "100% free from ads and unwanted content",
    imgUrl: "",
    imgAlt: "No ADS",
  },
  {
    title: "Positive Screen-time",
    discription: "A perfect learning guide through play",
    imgUrl: "",
    imgAlt: "Positive Screen-time",
  },
  {
    title: "Ever Growing Library",
    discription: "New content added regularly",
    imgUrl: "",
    imgAlt: "Ever Growing Library",
  },
  {
    title: "Create up to 3 Kids Profiles",
    discription: "Every child can have individual profile",
    imgUrl: "",
    imgAlt: "Create up to 3 Kids Profiles",
  },
];

export const HOME_PAGE_CONTENTS = [
  {
    id: "1",
    image: "/images/home/content_icons/anim_content_game_animal_day_care.png",
  },
  {
    id: "2",
    image: "/images/home/content_icons/anim_content_game_aquarium_game.png",
  },
  {
    id: "3",
    image: "/images/home/content_icons/anim_content_game_bake_the_cake.png",
  },
  {
    id: "4",
    image: "/images/home/content_icons/anim_content_game_cake_shop.png",
  },
  {
    id: "5",
    image: "/images/home/content_icons/anim_content_game_color_the_fruit.png",
  },
  {
    id: "6",
    image: "/images/home/content_icons/anim_content_game_coloring_fun.png",
  },
  {
    id: "7",
    image: "/images/home/content_icons/anim_content_game_cotton_candy_shop.png",
  },
  {
    id: "8",
    image: "/images/home/content_icons/anim_content_game_day_at_school.png",
  },
  {
    id: "9",
    image: "/images/home/content_icons/anim_content_game_doll_house.png",
  },
  {
    id: "10",
    image: "/images/home/content_icons/anim_content_game_fun_baby_day_care.png",
  },
  {
    id: "11",
    image: "/images/home/content_icons/anim_content_game_guitar.png",
  },
  {
    id: "12",
    image: "/images/home/content_icons/anim_content_game_i_am_dentist.png",
  },
  {
    id: "13",
    image: "/images/home/content_icons/anim_content_game_icecream_truck.png",
  },
  {
    id: "14",
    image: "/images/home/content_icons/anim_content_game_juice_it_up.png",
  },
  {
    id: "15",
    image: "/images/home/content_icons/anim_content_game_kids_supermarket.png",
  },
  {
    id: "16",
    image: "/images/home/content_icons/anim_content_game_magic_fill.png",
  },
  {
    id: "17",
    image: "/images/home/content_icons/anim_content_game_mermaid_princes.png",
  },
  {
    id: "18",
    image: "/images/home/content_icons/anim_content_game_my_dentist.png",
  },
  {
    id: "19",
    image: "/images/home/content_icons/anim_content_game_my_dream_hospital.png",
  },
  { id: "20", image: "/images/home/content_icons/anim_content_game_piano.png" },
  {
    id: "21",
    image: "/images/home/content_icons/anim_content_game_real_paint_animal.png",
  },
  {
    id: "22",
    image:
      "/images/home/content_icons/anim_content_game_real_paint_blank_canvas.png",
  },
  {
    id: "23",
    image: "/images/home/content_icons/anim_content_game_real_paint_flower.png",
  },
  {
    id: "24",
    image:
      "/images/home/content_icons/anim_content_game_real_paint_playbees.png",
  },
  {
    id: "25",
    image:
      "/images/home/content_icons/anim_content_game_real_paint_toony_vehicle.png",
  },
  {
    id: "26",
    image: "/images/home/content_icons/anim_content_game_smoothie_maker.png",
  },
  {
    id: "27",
    image: "/images/home/content_icons/anim_content_game_time_training.png",
  },
  {
    id: "28",
    image: "/images/home/content_icons/anim_content_game_xylophone.png",
  },
  {
    id: "29",
    image: "/images/home/content_icons/anim_content_game_ruby_fashion.png",
  },
  {
    id: "30",
    image:
      "/images/home/content_icons/anim_content_game_trace_alphabet_uppercase.png",
  },
];

export const HOME_PAGE_REVIEW_DATA = [
  {
    review: `Things I love about FirstCry PlayBees app is It help kids to learn and grow, it is made for kids age 0-6 years old, it has parental locks, they have read aloud storybooks, It's easy to navigate, you should try!`,
    reviewerName: `Reggiebel Buduan`,
    reviewerPhoto: "images/home/reviews/reviewer_photo_reggiebel_buduan.jpg",
  },
  {
    review: `The app is super interactive and Sky was easily able to navigate it on his own. His favorite activities are Painting, Dino World, Planets and Numbers. The app is working very well and Sky enjoys learning with games.`,
    reviewerName: `Jane Domingo`,
    reviewerPhoto: "images/home/reviews/reviewer_photo_jane_domingo.jpg",
  },
  {
    review: `Play-based learning is done through guided play. It keeps motivating kids and allows them to enjoy while learning. PlayBees allows kids to learn ABCs & numbers in a fun way with games, nursery rhymes and stories.`,
    reviewerName: `Mary Janzhel Madriaga`,
    reviewerPhoto:
      "images/home/reviews/reviewer_photo_mary_janzhel_madriaga.jpg",
  },
  {
    review: `FirstCry PlayBees app is a very helpful app for preschoolers. It has nursery rhymes, abcs, 123, shapes, animals, colours, fables that kids love playing. My Daughter, Sam, really loves the stories & solar system.`,
    reviewerName: `Sarah Mae Morales`,
    reviewerPhoto: "images/home/reviews/reviewer_photo_sarah_mae_morales.jpg",
  },
];

export const getOurAppsData = (isMobile = false) => {
  return [
    {
      appName: `FirstCry PlayBees`,
      description: `One of the best ways to teach kids their first ABC's and 123 numbers is through fun games that are educational and entertaining! FirstCry PlayBees app helps kids learn alphabet and their phonics, spellings and how to write (tracing) with lots of toddler learning games and activities. It has a collection of popular nursery rhymes, bedtime lullabies & songs for babies, It also helps kids learn to read kindergarten stories. Toddlers can learn English alphabet, numbers, counting with fun popping, splashing and puzzle games.`,
      bannerImgPath: "/images/apps/banner/playbees_app.png",
      playStoreUrl: `https://play.google.com/store/apps/details?id=com.firstcry.playbees.learn.grow.kids.preschool.toddler.numbers.abc.rhymes.games.stories.books`,
      appStoreUrl: `https://apps.apple.com/us/app/firstcry-playbees-kids-games/id1529402533`,
      appIconPath: "/images/apps/appicons/playbees.png",
      cssClassName: "playbees",
    },
    {
      appName: `Coloring Book - Draw & Learn`,
      description: `Welcome to Coloring Game for your child, the Sketchbook app for young artists! The app offers a variety of drawing tools, including crayons, glitters, and brushes, so kids can experiment with different styles and techniques. Coloring Games for Kids is a fun and educational app that helps kids develop their creativity and drawing skills. The app features a variety of coloring pages, including games such as Real painting animals, Blank canvas, Real Painting Dinosaurs, Toony Vehicles, Birds, Landscapes, Fruits, and much more.`,
      bannerImgPath: "/images/apps/banner/coloring_app.png",
      playStoreUrl: `https://play.google.com/store/apps/details?id=com.playbees.coloring`,
      appStoreUrl: ``,
      appIconPath: "/images/apps/appicons/coloring.png",
      cssClassName: "coloring",
    },
    {
      appName: `123 Math Games for Kids`,
      description: `Welcome to a world of mathematical wonder with our educational app designed for kids. Dive into the realm of mathematics, where learning becomes an exciting adventure! Our app is packed with engaging games that make learning numbers and counting a delightful experience for kids of all ages.`,
      bannerImgPath: "/images/apps/banner/numbers_app.png",
      playStoreUrl: `https://play.google.com/store/apps/details?id=com.playbees.numbers`,
      appStoreUrl: ``,
      appIconPath: "/images/apps/appicons/numbers.png",
      cssClassName: isMobile ? "" : `numbers`,
    },
    {
      appName: `Babies Music & Song Tutor`,
      description: `World of musical discovery with our Babies First Music, Song Tutor app. Whether you aspire to master various musical instruments, delve into music theory, or create your own musical compositions, our app offers an all-encompassing experience for learners of all ages`,
      bannerImgPath: "/images/apps/banner/music_app.png",
      playStoreUrl: `https://play.google.com/store/apps/details?id=com.playbees.musical`,
      appStoreUrl: ``,
      appIconPath: "/images/apps/appicons/musical.png",
      cssClassName: "",
    },
    {
      appName: `Cooking & Hotel Games for Kids`,
      description: `Are you ready to embark on a delicious journey through a world of delicious cakes, bakery stories, and baking games that are so yummy they'll make your town buzz with excitement? Our cooking simulator app filled with cooking dash challenges is a great way for your kid to become a master chef. Get ready to manage your very own cafe and create your own restaurant story that's hotter than burger shops in the summer.`,
      bannerImgPath: "/images/apps/banner/cooking_app.png",
      playStoreUrl: `https://play.google.com/store/apps/details?id=com.playbees.cooking`,
      appStoreUrl: ``,
      appIconPath: "/images/apps/appicons/cooking.png",
      cssClassName: "cooking",
    },
    {
      appName: `Fashion Designer - Kids Games`,
      description: `Take on the role of the ultimate super wedding & fashion stylist. Accessorize the bride with exclusive dresses and jewelry! Experiment with different styles, from classic and timeless to modern and trendy, to satisfy every bride's vision. As the bridal stylist, you hold the key to making their wedding day truly unforgettable. But it's not just about the dresses! Your boutique will also feature a wide selection of accessories, including veils, tiaras, jewelry, and shoes. Choose the perfect pieces to complement each gown and enhance the bride's overall look.`,
      bannerImgPath: "/images/apps/banner/fashion_app.png",
      playStoreUrl: `https://play.google.com/store/apps/details?id=com.playbees.bridalsalon`,
      appStoreUrl: ``,
      appIconPath: "/images/apps/appicons/fashion.png",
      cssClassName: "fashion",
    },
    {
      appName: `Halloween Magic World School`,
      description: `Welcome to the captivating world of Halloween Magic School! Engage in an extraordinary ideal adventure where girls become powerful wizards. Unlock the secrets of potion craft, cultivation, and operation, as you tap into a realm of enchantment. Immerse yourself in a mesmerizing world of magic and magical tricks. Experience the thrill of wielding a magic wand as you embrace the fun-filled Halloween spirit. In this discover the wonders of wizardry, spells, potions. Unleash your imagination and unshackle the dormant power of wizardry.`,
      bannerImgPath: "/images/apps/banner/halloween_app.png",
      playStoreUrl: `https://play.google.com/store/apps/details?id=com.playbees.magicacademy`,
      appStoreUrl: ``,
      appIconPath: "/images/apps/appicons/halloween.png",
      cssClassName: "halloween",
    },
  ];
};
