import { Grid, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { SPEAKER_DIVINE, SUB_MENU_HIGHLIGHT } from "../../Constants";

const SpeakerSubMenu = ({ title, textToHighLight }) => {
  const history = useHistory();

  const navigateToHome = () => {
    if (title === SPEAKER_DIVINE) {
      history.push("/divine");
    } else {
      history.push("/podcast");
    }
  };

  const navigateToSpeakerTNC = () => {
    history.push("/speakertnc", { title });
  };

  const onSongListClick = () => {
    if (title === SPEAKER_DIVINE) {
      window.open("/resources/Physical_device_song_list_divine.pdf", "_blank");
    } else {
      window.open("/resources/Physical_device_song_list_podcast.pdf", "_blank");
    }
  };

  const onUserManualClick = () => {
    if (title === SPEAKER_DIVINE) {
      window.open(
        "/resources/Physical_Device_User_Manual_Divine.pdf",
        "_blank"
      );
    } else {
      window.open(
        "/resources/Physical_Device_User_Manual_Podcast.pdf",
        "_blank"
      );
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2.5}
    >
      <Grid item>
        <Typography className="speaker-sub-menu-title" variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className="speaker-sub-menu"
          variant="h6"
          style={
            textToHighLight === SUB_MENU_HIGHLIGHT.HOME
              ? { color: "#e87619" }
              : {}
          }
          onClick={
            textToHighLight === SUB_MENU_HIGHLIGHT.TNC
              ? navigateToHome
              : undefined
          }
        >
          {textToHighLight === SUB_MENU_HIGHLIGHT.HOME ? <u>Home</u> : `Home`}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className="speaker-sub-menu"
          variant="h6"
          onClick={onSongListClick}
        >
          Song List
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className="speaker-sub-menu"
          variant="h6"
          onClick={onUserManualClick}
        >
          User Manual
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          className="speaker-sub-menu"
          variant="h6"
          onClick={
            textToHighLight === SUB_MENU_HIGHLIGHT.HOME
              ? navigateToSpeakerTNC
              : undefined
          }
          style={
            textToHighLight === SUB_MENU_HIGHLIGHT.TNC
              ? { color: "#e87619" }
              : {}
          }
        >
          {textToHighLight === SUB_MENU_HIGHLIGHT.TNC ? (
            <u>Terms & Conditions</u>
          ) : (
            `Terms & Conditions`
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SpeakerSubMenu;
