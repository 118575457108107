import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RedirectToFaq = () => {
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const mobile = params.get("mobile");

    if (mobile === "true") {
      history.replace("/faq/mobile");
    } else {
      history.replace("/faq");
    }
  }, [history]);

  return null;
};

export default RedirectToFaq;
