import React from "react";
import { Grid, Typography } from "@mui/material";

const ReviewMobileView = ({ reviewText, reviewerName, reviewerPhoto }) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <div style={{ position: "relative" }}>
          <img
            src={reviewerPhoto}
            alt="PlayBees Reviewer"
            style={{ width: "100%" }}
          />

          <img
            src="images/home/reviews/reviewer_frame.png"
            alt="PlayBees Reviewer"
            style={{
              color: "transparent",
              width: "100%",
              position: "absolute",
              inset: 0,
            }}
          />
        </div>
      </Grid>

      <Grid
        item
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sx={{ width: "100%", textAlign: "start" }}>
          <img
            src="images/home/reviews/starting_double_quote.png"
            alt="PlayBees App"
            style={{ width: "20%", height: "auto" }}
          />
        </Grid>

        <Grid item>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              textAlign: "center",
              fontSize: "1.5rem",
              fontFamily: `Jelly !important`,
              color: "#5e5c90",
              letterSpacing: "0px",
            }}
          >
            Recommended By
          </Typography>
          <Typography
            variant="h3"
            gutterBottom
            sx={{
              textAlign: "center",
              fontSize: "1.5rem",
              fontFamily: `Jelly !important`,
              color: "#5e5c90",
              letterSpacing: "0px",
            }}
          >
            Moms Worldwide
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              marginTop: "10px",
              marginBottom: 0,
              color: "#5e5c90",
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            textAlign="center"
          >
            {reviewText}
          </Typography>
        </Grid>

        <Grid item width={{ width: "100%", textAlign: "end" }}>
          <img
            src="images/home/reviews/ending_double_quote.png"
            alt="PlayBees App"
            style={{
              width: "20%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontSize: "1.2rem", marginTop: "15px", color: "#5e5c90" }}
        >
          {reviewerName}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReviewMobileView;
