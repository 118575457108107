export const style_pageTitle = {
  fontSize: "2.6rem",
  letterSpacing: "0px",
  marginTop: "120px",
  marginBottom: "20px",
  textAlign: "center",
  fontFamily: `Jelly !important`,
  color: "#5e5c90",
};

export const style_pageTitle_mobile = {
  ...style_pageTitle,
  fontSize: "1.8rem",
};

export const style_homePageTitle = {
  fontSize: "2.6rem",
  letterSpacing: "0px",
  marginTop: "70px",
  marginBottom: "40px",
  textAlign: "center",
  fontFamily: `Jelly !important`,
  color: "#5e5c90",
};

export const style_reviwerName = {
  color: "#5e5c90",
  fontWeight: "bold",
};

export const style_pageParent = {
  backgroundColor: "#fefefe",
  marginTop: "100px",
};

export const style_homePageTitle_Mobile = {
  ...style_homePageTitle,
  fontSize: "1.8rem",
};

export const style_contactUsText = {
  fontSize: "1rem",
};
