import "./Banner.css";

const Banner = ({ images, speed = 5000, direction = "rtl" }) => {
  return (
    <div className="inner">
      <div
        className={
          direction === "rtl" ? "wrapperRightToLeft" : "wrapperLeftToRight"
        }
      >
        <section
          className={
            direction === "rtl"
              ? "imgSectionRightToLeft"
              : "imgSectionLeftToRight"
          }
          style={{ "--speed": `${speed}ms` }}
        >
          {images.map(({ id, image }) => (
            <div className="image" key={id}>
              <img src={image} alt={id} className="imgIcon" />
            </div>
          ))}
        </section>
        <section
          className={
            direction === "rtl"
              ? "imgSectionRightToLeft"
              : "imgSectionLeftToRight"
          }
          style={{ "--speed": `${speed}ms` }}
        >
          {images.map(({ id, image }) => (
            <div className="image" key={id}>
              <img src={image} alt={id} className="imgIcon" />
            </div>
          ))}
        </section>
        <section
          className={
            direction === "rtl"
              ? "imgSectionRightToLeft"
              : "imgSectionLeftToRight"
          }
          style={{ "--speed": `${speed}ms` }}
        >
          {images.map(({ id, image }) => (
            <div className="image" key={id}>
              <img src={image} alt={id} className="imgIcon" />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export { Banner };
