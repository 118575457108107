import { Container, Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import "./../CommonStyle.css";
import { sendEvent } from "./../../Config/AnalyticsConfig";
import { Helmet } from "react-helmet";
import { style_pageTitle, style_pageTitle_mobile } from "../CommonStyle";
import { getOurAppsData } from "../../info";
import AppItem from "./AppItem";
import AppItemMobile from "./AppItemMobile";

const Apps = () => {
  const isMobile = window.innerWidth <= 960;

  let OUR_APPS_DATA = getOurAppsData(isMobile);

  useEffect(() => {
    sendEvent(`apps_page_load`);
  }, []);

  return (
    <Container>
      <Helmet>
        <title>
          FirstCry PlayBees | Learn ABCs & Story Reading Apps For Kids
        </title>
        <meta
          name="keywords"
          content="kids application, educational app, reading apps, learning app, firstcry, learn, preschool kids, homeschooling, learn at home, for toddlers, songs for babies"
        />
        <meta
          name="description"
          content="Preschool educational app for kids that has 1000+ toddler learning games, baby songs, nursery rhymes and kindergarten story books for reading children!"
        />
        <meta name="author" content="FirstCry PlayBees" />
      </Helmet>
      <Typography
        variant="h3"
        gutterBottom
        sx={isMobile ? style_pageTitle_mobile : style_pageTitle}
      >
        Our Apps
      </Typography>
      {isMobile ? (
        <>
          {OUR_APPS_DATA.map((item, index) => {
            if (index === 0)
              return (
                <AppItemMobile
                  key={index}
                  bannerImg={item.bannerImgPath}
                  title={item.appName}
                  description={item.description}
                  appIcon={item.appIconPath}
                  playStoreUrl={item.playStoreUrl}
                  appStoreUrl={item.appStoreUrl}
                  cssClassName={item.cssClassName}
                />
              );
            else
              return (
                <>
                  <br />
                  <br />
                  <AppItemMobile
                    key={index}
                    bannerImg={item.bannerImgPath}
                    title={item.appName}
                    description={item.description}
                    appIcon={item.appIconPath}
                    playStoreUrl={item.playStoreUrl}
                    appStoreUrl={item.appStoreUrl}
                    cssClassName={item.cssClassName}
                  />
                </>
              );
          })}
        </>
      ) : (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ margin: 0, width: "100%" }}
        >
          <Grid item container justifyContent="center" lg={5.5} xl={5.5}>
            <AppItem
              item
              bannerImg={OUR_APPS_DATA[0].bannerImgPath}
              title={OUR_APPS_DATA[0].appName}
              description={OUR_APPS_DATA[0].description}
              appIcon={OUR_APPS_DATA[0].appIconPath}
              playStoreUrl={OUR_APPS_DATA[0].playStoreUrl}
              appStoreUrl={OUR_APPS_DATA[0].appStoreUrl}
              cssClassName={OUR_APPS_DATA[0].cssClassName}
            />
          </Grid>
          <Grid item xs={1} md={1} />
          <Grid
            item
            container
            justifyContent="center"
            sx={isMobile ? { marginTop: 5 } : {}}
            lg={5.5}
            xl={5.5}
          >
            <AppItem
              item
              bannerImg={OUR_APPS_DATA[1].bannerImgPath}
              title={OUR_APPS_DATA[1].appName}
              description={OUR_APPS_DATA[1].description}
              appIcon={OUR_APPS_DATA[1].appIconPath}
              playStoreUrl={OUR_APPS_DATA[1].playStoreUrl}
              appStoreUrl={OUR_APPS_DATA[1].appStoreUrl}
              cssClassName={OUR_APPS_DATA[1].cssClassName}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            lg={5.5}
            xl={5.5}
            sx={{ marginTop: 5 }}
          >
            <AppItem
              item
              bannerImg={OUR_APPS_DATA[2].bannerImgPath}
              title={OUR_APPS_DATA[2].appName}
              description={OUR_APPS_DATA[2].description}
              appIcon={OUR_APPS_DATA[2].appIconPath}
              playStoreUrl={OUR_APPS_DATA[2].playStoreUrl}
              appStoreUrl={OUR_APPS_DATA[2].appStoreUrl}
              cssClassName={OUR_APPS_DATA[2].cssClassName}
            />
          </Grid>
          <Grid item xs={1} md={1} />
          <Grid
            item
            container
            justifyContent="center"
            lg={5.5}
            xl={5.5}
            sx={{ marginTop: 5 }}
          >
            <AppItem
              item
              bannerImg={OUR_APPS_DATA[3].bannerImgPath}
              title={OUR_APPS_DATA[3].appName}
              description={OUR_APPS_DATA[3].description}
              appIcon={OUR_APPS_DATA[3].appIconPath}
              playStoreUrl={OUR_APPS_DATA[3].playStoreUrl}
              appStoreUrl={OUR_APPS_DATA[3].appStoreUrl}
              cssClassName={OUR_APPS_DATA[3].cssClassName}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            sx={{ marginTop: 5 }}
            lg={5.5}
            xl={5.5}
          >
            <AppItem
              item
              bannerImg={OUR_APPS_DATA[4].bannerImgPath}
              title={OUR_APPS_DATA[4].appName}
              description={OUR_APPS_DATA[4].description}
              appIcon={OUR_APPS_DATA[4].appIconPath}
              playStoreUrl={OUR_APPS_DATA[4].playStoreUrl}
              appStoreUrl={OUR_APPS_DATA[4].appStoreUrl}
              cssClassName={OUR_APPS_DATA[4].cssClassName}
            />
          </Grid>
          <Grid item xs={1} md={1} />
          <Grid
            item
            container
            justifyContent="center"
            sx={{ marginTop: 5 }}
            lg={5.5}
            xl={5.5}
          >
            <AppItem
              item
              bannerImg={OUR_APPS_DATA[5].bannerImgPath}
              title={OUR_APPS_DATA[5].appName}
              description={OUR_APPS_DATA[5].description}
              appIcon={OUR_APPS_DATA[5].appIconPath}
              playStoreUrl={OUR_APPS_DATA[5].playStoreUrl}
              appStoreUrl={OUR_APPS_DATA[5].appStoreUrl}
              cssClassName={OUR_APPS_DATA[5].cssClassName}
            />
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            sx={{ marginTop: 5 }}
            lg={5.5}
            xl={5.5}
          >
            <AppItem
              item
              bannerImg={OUR_APPS_DATA[6].bannerImgPath}
              title={OUR_APPS_DATA[6].appName}
              description={OUR_APPS_DATA[6].description}
              appIcon={OUR_APPS_DATA[6].appIconPath}
              playStoreUrl={OUR_APPS_DATA[6].playStoreUrl}
              appStoreUrl={OUR_APPS_DATA[6].appStoreUrl}
              cssClassName={OUR_APPS_DATA[6].cssClassName}
            />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Apps;
