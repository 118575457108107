import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 *
 * This component is required to solve issue with react router issue is
 * assume on home page and scrolled till bottom of the page and when I navigate
 * to another page ex. reviews page / privacy page then I land on page with my current
 * position at bottom of the page. Ideally I should be on top of the page. So to solve
 * this issue we have introduced this component.
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
