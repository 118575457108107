import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./../CommonStyle.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { sendEvent } from "./../../Config/AnalyticsConfig";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import "./Home.css";
import FeatureItem from "./FeatureItem";
import { Banner } from "./Banner";
import {
  style_homePageTitle,
  style_homePageTitle_Mobile,
} from "./../CommonStyle";
import {
  getHomePageCarousalItems,
  HOME_PAGE_CONTENTS,
  getHomePageCuratedContentData,
  HOME_PAGE_REVIEW_DATA,
} from "../../info";
import FeatureItemMobile from "./FeatureItemMobile";
import Review from "./Review";
import ReviewMobileView from "./ReviewMobileView";

const Home = () => {
  const isMobile = window.innerWidth <= 960;

  useEffect(() => {
    sendEvent(`home_page_load`);
  }, []);

  const onOpenLinkInNewTabClick = (link) => {
    window.open(link, "_blank");
  };

  const getTopCarousalSection = () => {
    return (
      <>
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          stopOnHover={false}
          swipeable={true}
          renderIndicator={(onClickHandler, isSelected, index, label) => {
            const style = {
              marginLeft: 10,
              cursor: "pointer",
              border: "none",
              width: "auto",
              padding: 0,
              background: "transparent",
            };

            const selectedImage =
              "images/home/top_banners/mobile/slide_dot_active.png";
            const unselectedImage =
              "images/home/top_banners/mobile/slide_dot_normal.png";

            return (
              <button
                style={style}
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                key={index}
                tabIndex={0}
              >
                <img
                  src={isSelected ? selectedImage : unselectedImage}
                  alt={`indicator-${index}`}
                  aria-label={`${label} ${index + 1}`}
                />
              </button>
            );
          }}
        >
          {getHomePageCarousalItems(isMobile).map((item, index) => {
            return (
              <div key={index}>
                <img src={item.imgUrl} alt={item.imgAlt} />
              </div>
            );
          })}
        </Carousel>
      </>
    );
  };

  const getAwardWinningSection = () => {
    return (
      <>
        <Typography
          variant="h3"
          gutterBottom
          sx={
            isMobile
              ? style_homePageTitle_Mobile
              : { ...style_homePageTitle, marginBottom: "0px" }
          }
        >
          Award Winning App For Preschoolers
        </Typography>

        {isMobile ? (
          <>
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="stretch"
            >
              <Grid
                item
                xs={4.5}
                sm={4.5}
                md={4.5}
                className="award-item"
                container
                alignItems="center"
                justifyContent="center"
              >
                <img
                  src="/images/home/award_winning/teachers_approved_badge.png"
                  alt="PlayBees Teachers Approved"
                  style={{ width: "80%" }}
                />
                <Typography className="award-title">
                  Teacher's Approved
                </Typography>
              </Grid>
              <Grid item xs={4.5} sm={4.5} md={4.5} className="award-item">
                <img
                  src="/images/home/award_winning/educational_badge.png"
                  alt="PlayBees Educational Badge"
                  style={{ width: "100%" }}
                />
                <Typography className="award-title">
                  Educational App Store Certified
                </Typography>
              </Grid>
            </Grid>
            <br />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                xs={7}
                sm={7}
                md={7}
                sx={{
                  borderRadius: "30px",
                }}
                className="award-item"
              >
                <img
                  src="/images/home/award_winning/kids_safe_badge.png"
                  alt="PlayBees Kids Safe Certified"
                  style={{ width: "100%" }}
                />
                <Typography className="award-title">
                  Kids Safe Certified
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={4} sm={4} md={4}>
                <img
                  src="/images/home/award_winning/playbees_joe.png"
                  alt="PlayBees Emma"
                  style={{ width: "70%", marginLeft: "15%" }}
                />
              </Grid>
              <Grid item xs={4} sm={4} md={4}>
                <img
                  src="/images/home/award_winning/playbees_emma.png"
                  alt="PlayBees Emma"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ marginBottom: "50px" }}
            >
              <Grid
                container
                justifyContent="flex-end"
                item
                lg={3}
                xl={3}
                alignItems="flex-end"
              >
                <img
                  src="images/home/award_winning/playbees_joe.png"
                  alt="Approved By Teachers and Parents"
                  style={{ width: "50%", height: "50%", paddingTop: "150px" }}
                />
              </Grid>

              <Grid
                item
                lg={6}
                xl={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="stretch"
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    item
                    xl={6}
                    lg={6}
                  >
                    <Grid item textAlign="center">
                      <img
                        src="images/home/award_winning/teachers_approved_badge.png"
                        alt="Approved By Teachers and Parents"
                        style={{ width: "80%" }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          color: "#686885",
                        }}
                      >
                        Teacher's Approved
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    item
                    xl={6}
                    lg={6}
                  >
                    <Grid item textAlign="center">
                      <img
                        src="images/home/award_winning/educational_badge.png"
                        alt="Playbees Educational App Store Certified"
                        style={{
                          width: "75%",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          onOpenLinkInNewTabClick(
                            `https://www.educationalappstore.com/app/firstcry-playbees-play-learn`
                          )
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        gutterBottom
                        style={{
                          color: "#686885",
                        }}
                        textAlign="center"
                      >
                        Educational App Store Certified
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  item
                  direction="column"
                >
                  <Grid item textAlign="center">
                    <img
                      src="images/home/award_winning/kids_safe_badge.png"
                      alt="Playbees Kids Safe"
                      style={{ width: "80%", marginTop: "20px" }}
                    />
                  </Grid>
                  <Grid>
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{
                        marginBottom: "0px",
                        marginTop: "10px",
                        color: "#686885",
                      }}
                      textAlign="center"
                    >
                      Kids Safe Certified
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="flex-start"
                item
                lg={3}
                xl={3}
                alignItems="flex-end"
              >
                <img
                  src="images/home/award_winning/playbees_emma.png"
                  alt="Playbees Emma"
                  style={{ width: "70%", height: "70%", paddingTop: "150px" }}
                />
              </Grid>
            </Grid>{" "}
          </>
        )}
      </>
    );
  };

  const getFunLearningSection = () => {
    return (
      <>
        {isMobile ? (
          <>
            <div>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  ...style_homePageTitle_Mobile,
                  marginBottom: 0,
                  marginTop: "20px",
                }}
              >
                About PlayBees
              </Typography>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  ...style_homePageTitle_Mobile,
                  marginTop: "20px",
                  fontSize: "1.1rem",
                  marginBottom: "20px",
                }}
              >
                #1 Fun Learning App For Homeschooling
              </Typography>
            </div>

            <div>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid
                  item
                  xs={4.5}
                  sm={4.5}
                  md={4.5}
                  sx={{ marginBottom: "-3%" }}
                >
                  <img
                    src="/images/home/fun_learning/game_balloon.png"
                    alt="PlayBees Balloon"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={4} sm={4} md={4} sx={{ marginRight: "2%" }}>
                  <img
                    src="/images/home/fun_learning/farmer.png"
                    alt="PlayBees Farmer"
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>

              <Grid item container direction="column">
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginBottom: "9%", marginTop: "9%", zIndex: 1 }}
                >
                  <Grid item xs sm md style={{ textAlign: "-webkit-left" }}>
                    <div className="fun-learning-content-container">
                      <img
                        src="/images/home/fun_learning/games.png"
                        alt="PlayBees Games"
                        style={{
                          width: "100%",
                        }}
                      />
                      <Typography className="fun-learning-content-title">
                        Games
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs sm md style={{ textAlign: "-webkit-right" }}>
                    <div className="fun-learning-content-container">
                      <img
                        src="/images/home/fun_learning/rhymes.png"
                        alt="PlayBees Rhymes"
                        style={{ width: "100%" }}
                      />
                      <Typography className="fun-learning-content-title">
                        Rhymes
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                <img
                  src="/images/home/fun_learning/emma_circle.png"
                  alt="des"
                  className="fun-learning-emma"
                />

                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ marginTop: "10%", marginBottom: "5%", zIndex: 1 }}
                >
                  <Grid item xs sm md style={{ textAlign: "-webkit-left" }}>
                    <div className="fun-learning-content-container">
                      <img
                        src="/images/home/fun_learning/stories.png"
                        alt="PlayBees Stories"
                        style={{ width: "100%" }}
                      />
                      <Typography className="fun-learning-content-title">
                        Stories
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs sm md style={{ textAlign: "-webkit-right" }}>
                    <div className="fun-learning-content-container">
                      <img
                        src="/images/home/fun_learning/books.png"
                        alt="PlayBees Books"
                        style={{ width: "100%" }}
                      />
                      <Typography className="fun-learning-content-title">
                        Books
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Grid
                  item
                  xs={4.4}
                  sm={4.4}
                  md={4.4}
                  sx={{ marginBottom: "-3%" }}
                >
                  <img
                    src="/images/home/fun_learning/grandma_stories.png"
                    alt="PlayBees Balloon"
                    style={{ width: "100%" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={4.7}
                  sm={4.7}
                  md={4.7}
                  sx={{ marginRight: "1%" }}
                >
                  <img
                    src="/images/home/fun_learning/piper.png"
                    alt="PlayBees Farmer"
                    style={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </div>
          </>
        ) : (
          <>
            <div style={{ position: "absolute", left: "0px", right: "0px" }}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  ...style_homePageTitle,
                  marginBottom: "0px",
                  marginTop: "20px",
                }}
              >
                About PlayBees
              </Typography>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  ...style_homePageTitle,
                  marginTop: "20px",
                  marginBottom: "-20px",
                }}
              >
                #1 Fun Learning App For Homeschooling
              </Typography>
            </div>
            <div style={{ textAlign: "center" }}>
              <img
                src="images/home/fun_learning_banner.jpg"
                alt="Playbees Fun Learning App"
                style={{ width: "90%", height: "90%" }}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const getFeaturesSection = () => {
    return (
      <>
        <Typography
          variant="h3"
          gutterBottom
          sx={isMobile ? style_homePageTitle_Mobile : style_homePageTitle}
        >
          Features You And Your Little Ones Will Love
        </Typography>
        {isMobile ? (
          <>
            <FeatureItemMobile
              title="Parental Locks"
              body="Easily manage your child's profile and screen-time"
              imgUrl="/images/home/features/mobile/parental_lock.png"
              imgAlt="PlayBees Parental Lock"
              imgOnLeftSide={true}
            />
            <br /> <br />
            <FeatureItemMobile
              title="Kids Skills Report"
              body="Monitor your kids progress in detail"
              imgUrl="/images/home/features/mobile/kids_skill_report.png"
              imgAlt="PlayBees Kids Skills Report"
              imgOnLeftSide={false}
            />
            <br /> <br />
            <FeatureItemMobile
              title="No ADS"
              body="100% free from ads and unwanted content"
              imgUrl="/images/home/features/mobile/no_ads.png"
              imgAlt="PlayBees No ADS"
              imgOnLeftSide={true}
            />
            <br /> <br />
            <FeatureItemMobile
              title="Positive Screen-time"
              body="A perfect learning guide through play"
              imgUrl="/images/home/features/mobile/positive_screen_time.png"
              imgAlt="PlayBees Positive Screen-time"
              imgOnLeftSide={false}
            />
            <br /> <br />
            <FeatureItemMobile
              title="Ever Growing Library"
              body="New content added regularly"
              imgUrl="/images/home/features/mobile/ever_growing_library.png"
              imgAlt="PlayBees Every Growing Library"
              imgOnLeftSide={true}
            />
            <br /> <br />
            <FeatureItemMobile
              title="Create up to 3 Kids Profiles"
              body="Every child can have an individual profile"
              imgUrl="/images/home/features/mobile/create_kids_profile.png"
              imgAlt="PlayBees Create Kids Profile"
              imgOnLeftSide={false}
            />
          </>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              <FeatureItem
                imgSrc="images/home/features/web/parental_lock.png"
                title="Parental Locks"
                subTitle="Easily manage your child's profile and screen-time"
                imgWidth="80%"
              />
              <FeatureItem
                imgSrc="images/home/features/web/kids_skill_report.png"
                title="Kids Skills Report"
                subTitle="Monitor your kids progress in detail"
                imgWidth="80%"
              />
              <FeatureItem
                imgSrc="images/home/features/web/no_ads.png"
                title={`No ADS`}
                subTitle="100% free from ads and unwanted content"
                imgWidth="55%"
              />
            </Grid>
            <br />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
            >
              <FeatureItem
                imgSrc="images/home/features/web/positive_screen_time.png"
                title="Positive Screen-time"
                subTitle="A perfect learning guide through play"
                imgWidth="80%"
              />
              <FeatureItem
                imgSrc="images/home/features/web/ever_growing_library.png"
                title="Ever Growing Library"
                subTitle="New content added regulary"
                imgWidth="80%"
              />
              <FeatureItem
                imgSrc="images/home/features/web/create_kids_profile.png"
                title="Create up to 3 Kids Profiles"
                subTitle="Every child can have an individual profile"
                imgWidth="80%"
              />
            </Grid>
          </>
        )}
      </>
    );
  };

  const getVideoSection = () => {
    return (
      <>
        <Typography
          variant="h3"
          gutterBottom
          sx={isMobile ? style_homePageTitle_Mobile : style_homePageTitle}
        >
          Join PlayBees And Be A Part Of 1 Million+ Kids Club
        </Typography>
        {isMobile ? (
          <>
            <div
              style={{
                backgroundImage: `url(${"images/home/video_bg_mobile.jpg"})`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={5} sm={5} md={5} sx={{ marginBottom: "25px" }}>
                  <div className="content-count-container">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-count"
                    >
                      500+
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-name"
                    >
                      Games
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={5} sm={5} md={5} sx={{ marginBottom: "25px" }}>
                  <div className="content-count-container">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-count"
                    >
                      300+
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      display="block"
                      className="content-name"
                    >
                      Books
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Grid item xs={5} sm={5} md={5}>
                  <div className="content-count-container">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-count"
                    >
                      150+
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-name"
                    >
                      Rhymes
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                  <div className="content-count-container">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-count"
                    >
                      60+
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-name"
                    >
                      Stories
                    </Typography>
                  </div>
                </Grid>
              </Grid>

              <div className="video-container">
                <iframe
                  src="https://www.youtube.com/embed/v0xsXQvWMVQ"
                  frameborder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{
                backgroundImage: `url(${"images/home/video_bg.jpg"})`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="flex-end"
                item
                lg={2.5}
                xl={2.5}
              >
                <div className="content-info-container-left">
                  <div className="content-count-container">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-count"
                    >
                      500+
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-name"
                    >
                      Games
                    </Typography>
                  </div>
                </div>
                <div
                  className="content-info-container-left"
                  style={{ marginBottom: 0, marginTop: "45px" }}
                >
                  <div className="content-count-container">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-count"
                    >
                      150+
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-name"
                    >
                      Rhymes
                    </Typography>
                  </div>
                </div>
              </Grid>
              <Grid item lg={7} xl={7}>
                <div className="video-container">
                  <iframe
                    src="https://www.youtube.com/embed/v0xsXQvWMVQ"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                  />
                </div>
              </Grid>
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                item
                lg={2.5}
                xl={2.5}
              >
                <div className="content-info-container-left content-info-container-right">
                  <div className="content-count-container">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-count"
                    >
                      300+
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      display="block"
                      className="content-name"
                    >
                      Books
                    </Typography>
                  </div>
                </div>
                <div
                  className="content-info-container-left content-info-container-right"
                  style={{ marginBottom: 0, marginTop: "45px" }}
                >
                  <div className="content-count-container">
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-count"
                    >
                      60+
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className="content-name"
                    >
                      Stories
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  const getEducationActivitySection = () => {
    return (
      <>
        <Typography
          variant="h3"
          gutterBottom
          sx={isMobile ? style_homePageTitle_Mobile : style_homePageTitle}
        >
          1000+ Educational Activities For Kids Early Learning
        </Typography>
        {isMobile ? (
          <>
            <Banner
              images={HOME_PAGE_CONTENTS.slice(0, 10)}
              speed={20000}
              direction="rtl"
            />
            <Banner
              images={HOME_PAGE_CONTENTS.slice(10, 20)}
              speed={20000}
              direction="ltr"
            />
            <Banner
              images={HOME_PAGE_CONTENTS.slice(20, 30)}
              speed={20000}
              direction="rtl"
            />
          </>
        ) : (
          <>
            <Banner
              images={HOME_PAGE_CONTENTS.slice(0, 15)}
              speed={20000}
              direction="rtl"
            />
            <Banner
              images={HOME_PAGE_CONTENTS.slice(15, 30)}
              speed={20000}
              direction="ltr"
            />
          </>
        )}
      </>
    );
  };

  const getReviewSection = () => {
    return (
      <>
        <div className="review-section">
          <br /> <br />
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={false}
            swipeable={true}
            showArrows={false}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const style = {
                marginLeft: 10,
                cursor: "pointer",
                border: "none",
                width: "auto",
                padding: 0,
                background: "transparent",
              };

              const selectedImage = isMobile
                ? "images/home/reviews/mobile/slider_line.png"
                : "images/home/reviews/web/slider_line.png";
              const unselectedImage = isMobile
                ? "images/home/reviews/mobile/slider_dot.png"
                : "images/home/reviews/web/slider_dot.png";

              return (
                <button
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  key={index}
                  tabIndex={0}
                >
                  <img
                    src={isSelected ? selectedImage : unselectedImage}
                    alt={`indicator-${index}`}
                    aria-label={`${label} ${index + 1}`}
                  />
                </button>
              );
            }}
          >
            {isMobile
              ? HOME_PAGE_REVIEW_DATA.map((item, index) => {
                  return (
                    <ReviewMobileView
                      key={index}
                      reviewText={item.review}
                      reviewerName={item.reviewerName}
                      reviewerPhoto={item.reviewerPhoto}
                    />
                  );
                })
              : HOME_PAGE_REVIEW_DATA.map((item, index) => {
                  return (
                    <Review
                      key={index}
                      reviewText={item.review}
                      reviewerName={item.reviewerName}
                      reviewerPhoto={item.reviewerPhoto}
                    />
                  );
                })}
          </Carousel>
        </div>
      </>
    );
  };

  const getCuratedContentSection = () => {
    return (
      <>
        <Typography
          variant="h3"
          gutterBottom
          sx={isMobile ? style_homePageTitle_Mobile : style_homePageTitle}
        >
          Meticulously Curated Content For Your Kid's Early Learning
        </Typography>

        <div className="curated-content-section">
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            stopOnHover={false}
            swipeable={false}
            showArrows={false}
            centerMode
            centerSlidePercentage={isMobile ? 85 : 45}
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              const style = {
                marginLeft: isMobile ? -2 : 5,
                cursor: "pointer",
                border: "none",
                width: "auto",
                padding: 0,
                background: "transparent",
              };

              const selectedImage =
                "images/home/curated_contents/slide_dot_active.png";
              const unselectedImage =
                "images/home/curated_contents/slide_dot_normal.png";

              return (
                <button
                  style={style}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  key={index}
                  tabIndex={0}
                >
                  <img
                    src={isSelected ? selectedImage : unselectedImage}
                    alt={`indicator-${index}`}
                    aria-label={`${label} ${index + 1}`}
                    style={
                      isMobile
                        ? { width: "50%", height: "50%" }
                        : { width: "70%", height: "70%" }
                    }
                  />
                </button>
              );
            }}
          >
            {getHomePageCuratedContentData(isMobile).map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ marginLeft: "7px", marginRight: "8px" }}
                >
                  <img src={item.imgUrl} alt={item.imgAlt} />
                </div>
              );
            })}
          </Carousel>
        </div>
      </>
    );
  };

  const getDownloadSection = () => {
    return (
      <>
        {isMobile ? (
          <>
            <div style={{ position: "relative", marginTop: "70px" }}>
              <img
                src="/images/home/download_bg.jpg"
                alt="Download Background"
                style={{ width: "100%" }}
              />

              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  ...style_homePageTitle_Mobile,
                  marginTop: 0,
                  marginBottom: 0,
                  position: "absolute",
                  top: "2%",
                  left: 0,
                  right: "auto",
                }}
              >
                Download Our Most Loved PlayBees App
              </Typography>

              <img
                src="/images/home/playbees_home_logo.png"
                alt="PlayBees Home Logo"
                style={{
                  position: "absolute",
                  width: "54%",
                  top: "15%",
                  left: "23%",
                  right: "auto",
                }}
              />
              <Typography
                sx={{
                  position: "absolute",
                  top: "48%",
                  left: "29%",
                  right: "auto",
                  color: "gray",
                }}
              >
                Download App Now
              </Typography>

              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  top: "55%",
                }}
              >
                <img
                  src="/images/logos/googleplay_button.png"
                  alt="Download PlayBees App"
                  style={{ width: "40%", height: "auto", marginRight: "2%" }}
                  onClick={() =>
                    onOpenLinkInNewTabClick(
                      `https://play.google.com/store/apps/details?id=com.firstcry.playbees.learn.grow.kids.preschool.toddler.numbers.abc.rhymes.games.stories.books`
                    )
                  }
                />
                <img
                  src="/images/logos/appstore_button.png"
                  alt="Download PlayBees App"
                  style={{ width: "40%", height: "auto", marginLeft: "2%" }}
                  onClick={() => {
                    onOpenLinkInNewTabClick(
                      `https://apps.apple.com/us/app/firstcry-playbees-kids-games/id1529402533`
                    );
                  }}
                />
              </div>
              <img
                style={{
                  position: "absolute",
                  width: "30%",
                  bottom: "5%",
                  left: 0,
                }}
                src="images/home/playbees_tia.png"
                alt="PlayBees Tia"
              />
              <img
                src="images/home/playbees_dancing_emma.png"
                alt="PlayBees Emma"
                style={{
                  position: "absolute",
                  width: "33%",
                  bottom: "5%",
                  right: 0,
                }}
              />
              <Typography
                style={{
                  position: "absolute",
                  bottom: "12%",
                  left: "42%",
                  right: "auto",
                  color: "gray",
                  fontSize: "0.9rem",
                }}
              >
                Follow Us
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: "5%",
                  left: "30%",
                  right: "auto",
                  width: "40%",
                }}
              >
                <img
                  src="images/home/sm_youtube.png"
                  alt="PlayBees YouTube"
                  style={{ width: "25%", marginRight: "5%" }}
                  onClick={() => {
                    onOpenLinkInNewTabClick(
                      `https://youtube.com/@firstcryplaybees?si=jE4sGLq46eW6_t71`
                    );
                  }}
                />
                <img
                  src="images/home/sm_instagram.png"
                  alt="PlayBees Instagram"
                  style={{ width: "25%", marginLeft: "5%" }}
                  onClick={() => {
                    onOpenLinkInNewTabClick(
                      `https://www.instagram.com/firstcryplaybees?igsh=YTE4b2V2aGF6cmxv`
                    );
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Typography variant="h3" gutterBottom sx={style_homePageTitle}>
              Download Our Most Loved PlayBees App
            </Typography>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              paddingBottom="10px"
            >
              <Grid
                item
                xl={2.5}
                lg={2.5}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <img
                  src="images/home/playbees_tia.png"
                  alt="PlayBees Tia"
                  style={{ width: "65%" }}
                />
              </Grid>
              <Grid
                item
                xl={7}
                lg={7}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item textAlign="center">
                  <img
                    src="images/home/playbees_home_logo.png"
                    alt="PlayBees Logo"
                    width="75%"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      color: "#5e5c90",
                      marginBottom: "15px",
                      fontSize: "1.2rem",
                    }}
                  >
                    Download App Now
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item textAlign="right">
                    <img
                      src="images/logos/googleplay_button.png"
                      alt="FirstCry PlayBees App for Android - Google PlayStore"
                      width="80%"
                      style={{ marginRight: "10px", cursor: "pointer" }}
                      onClick={() =>
                        onOpenLinkInNewTabClick(
                          `https://play.google.com/store/apps/details?id=com.firstcry.playbees.learn.grow.kids.preschool.toddler.numbers.abc.rhymes.games.stories.books`
                        )
                      }
                    />
                  </Grid>
                  <Grid item textAlign="left">
                    <img
                      src="images/logos/appstore_button.png"
                      alt="FirstCry PlayBees App for iOS - iPhone and iPad"
                      width="80%"
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={() => {
                        onOpenLinkInNewTabClick(
                          `https://apps.apple.com/us/app/firstcry-playbees-kids-games/id1529402533`
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{
                      marginTop: "60px",
                      color: "#5e5c90",
                      fontSize: "1.2rem",
                    }}
                  >
                    Follow Us
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item textAlign="left">
                    <img
                      src="images/home/sm_youtube.png"
                      alt="PlayBees YouTube"
                      width="80%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onOpenLinkInNewTabClick(
                          `https://youtube.com/@firstcryplaybees?si=jE4sGLq46eW6_t71`
                        );
                      }}
                    />
                  </Grid>
                  <Grid item textAlign="right">
                    <img
                      src="images/home/sm_instagram.png"
                      alt="PlayBees Instagram"
                      width="80%"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onOpenLinkInNewTabClick(
                          `https://www.instagram.com/firstcryplaybees?igsh=YTE4b2V2aGF6cmxv`
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xl={2.5}
                lg={2.5}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-end"
              >
                <img
                  src="images/home/playbees_dancing_emma.png"
                  alt="PlayBees Emma"
                  style={{ width: "75%" }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  return (
    <Container maxWidth="xl" className="home-page-parent">
      <Helmet>
        <title>
          FirstCry PlayBees | Nursery Rhymes, Stories, Games For Kids
        </title>
        <meta
          name="keywords"
          content="educational, baby songs, baby games, kids games, toddler learning games, story book, read stories, puzzle, memory games, preschool, kindergarten, nursery rhymes"
        />
        <meta
          name="description"
          content="Preschool educational app for kids that has 1000+ toddler learning games, baby songs, nursery rhymes and kindergarten story books for reading children!"
        />
        <meta name="author" content="FirstCry PlayBees" />
      </Helmet>

      {getTopCarousalSection()}

      {/* Award winning section */}
      {getAwardWinningSection()}

      {/* Fun Learning section */}
      {getFunLearningSection()}

      {/* Features section */}
      {getFeaturesSection()}

      {/* Video section */}
      {getVideoSection()}

      {/* Educational Activity Section */}
      {getEducationActivitySection()}

      {/* Review Section */}
      {getReviewSection()}

      {/* Curated contents */}
      {getCuratedContentSection()}

      {/* Download section */}
      {getDownloadSection()}
    </Container>
  );
};

export default Home;
