import { Typography } from "@mui/material";
import React from "react";

const FeatureItemMobile = ({ title, body, imgUrl, imgAlt, imgOnLeftSide }) => {
  return (
    <div className="feature-item-container">
      <div
        className={`feature-item-info-container-right ${
          imgOnLeftSide ? "" : "feature-item-info-container-left"
        }`}
      >
        <Typography className="feature-item-title">{title}</Typography>
        <Typography className="feature-item-body">{body}</Typography>
      </div>
      <img
        className={`feature-item-info-img-left ${
          imgOnLeftSide ? "" : "feature-item-info-img-right"
        }`}
        src={imgUrl}
        alt={imgAlt}
      />
    </div>
  );
};

export default FeatureItemMobile;
