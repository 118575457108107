export const MenuItems = [
  {
    title: "Home",
    url: "/",
    cName: "nav-links",
    cLi: "nav-li",
    eventName: "home_menu_click",
  },
  {
    title: "Apps",
    url: "/apps",
    cName: "nav-links",
    cLi: "nav-li",
    eventName: "apps_menu_click",
  },
  {
    title: "Speaker",
    url: "",
    cName: "nav-links",
    cLi: "nav-li submenu",
    eventName: "",
  },
  {
    title: "Reviews",
    url: "/reviews",
    cName: "nav-links",
    cLi: "nav-li",
    eventName: "review_menu_click",
  },
  {
    title: "FAQ",
    url: "/faq",
    cName: "nav-links",
    cLi: "nav-li",
    eventName: "faq_menu_click",
  },
  {
    title: "Privacy",
    url: "/privacy",
    cName: "nav-links",
    cLi: "nav-li",
    eventName: "privacy_menu_click",
  },
  {
    title: "Contact Us",
    url: "/contactus",
    cName: "nav-links",
    cLi: "nav-li",
    eventName: "contact_us_menu_click",
  },
];

export const SpeakerMenuItems = [
  {
    title: "PlayBees Speaker Divine",
    url: "/divine",
    cName: "nav-links",
    cLi: "nav-li",
    eventName: "playbees_divine_speaker_click",
  },
  {
    title: "PlayBees Speaker Podcasts",
    url: "/podcast",
    cName: "nav-links",
    cLi: "nav-li",
    eventName: "playbees_podcast_speaker_click",
  },
];
