import { Grid, Typography } from "@mui/material";
import React from "react";

const FeatureItem = (props) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      alignItems="center"
      item
      lg={3}
      xl={3}
    >
      <Grid item textAlign="center">
        <img
          src={props.imgSrc}
          alt="Approved By Teachers and Parents"
          style={{ width: props.imgWidth, height: "auto" }}
        />
      </Grid>
      <Grid item>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            marginBottom: "0px",
            marginTop: "10px",
            color: "#5e5c90",
          }}
          textAlign="center"
        >
          {props.title}
        </Typography>
      </Grid>
      <Grid item container justifyContent="center">
        <Typography
          variant="h6"
          style={{
            marginBottom: "0px",
            width: "55%",
            fontSize: "1rem",
            textAlign: "center",
            color: "#5e5c90",
          }}
        >
          {props.subTitle}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FeatureItem;
