import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import FooterSection from "./Components/FooterSection";
import { Container } from "@mui/material";
import Apps from "./Components/Apps/Apps";
import Reviews from "./Components/Reviews/Reviews";
import Faq from "./Components/FAQ/Faq";
import Privacy from "./Components/Privacy/Privacy";
import ContactUs from "./Components/ContactUs/ContactUs";
import Home from "./Components/Home/Home";
import NavBarNew from "./Components/Navigation/NavBarNew";
import Divine from "./Components/Speaker/Divine/Divine";
import Podcast from "./Components/Speaker/Podcast/Podcast";
import SpeakerTNC from "./Components/Speaker/SpeakerTNC";
import ScrollToTop from "./Components/Navigation/ScrollToTop";
import RedirectToFaq from "./Components/Navigation/RedirectToFaq";
import RedirectToPrivacy from "./Components/Navigation/RedirectToPrivacy";

const App = () => {
  const isMobile = window.innerWidth <= 960;

  const getWebData = () => {
    return (
      <div>
        <NavBarNew />

        <Switch>
          <Route path="/apps" component={Apps} />
          <Route path="/reviews" component={Reviews} />
          <Route path="/faq.html" component={RedirectToFaq} />
          <Route path="/faq" component={Faq} />
          <Route path="/privacypolicy.html" component={RedirectToPrivacy} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/contactus" component={ContactUs} />
          {isMobile ? "" : <Route path="/divine" component={Divine} />}
          {isMobile ? "" : <Route path="/podcast" component={Podcast} />}
          {isMobile ? "" : <Route path="/speakertnc" component={SpeakerTNC} />}
          <Route path="/" component={Home} />
        </Switch>

        <FooterSection />
      </div>
    );
  };

  return (
    <>
      <div className="App-header" style={{ backgroundColor: "#fefefe" }}>
        <Container maxWidth="xl" style={{ padding: 0 }}>
          <Router>
            <ScrollToTop />
            <Switch>
              <Route path="/privacy/:viewfor" component={Privacy} />
              <Route path="/faq/:viewfor" component={Faq} />
              <Route component={getWebData} />
            </Switch>
          </Router>
        </Container>
      </div>
    </>
  );
};

export default App;
