import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const RedirectToPrivacy = () => {
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const mobile = params.get("mobile");
    const ssoPage = params.get("ssoPage");

    if (mobile === "true") {
      history.replace("/privacy/mobile");
    } else if (ssoPage === "true") {
      history.replace("/privacy/ssopage");
    } else {
      history.replace("/privacy");
    }
  }, [history]);

  return null;
};

export default RedirectToPrivacy;
