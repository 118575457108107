import { Container, Pagination, Rating, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./../CommonStyle.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ReviewItems } from "./ReviewItems";
import { sendEvent } from "./../../Config/AnalyticsConfig";
import { Helmet } from "react-helmet";
import {
  style_pageTitle,
  style_reviwerName,
  style_pageTitle_mobile,
} from "../CommonStyle";

function FormRow(props) {
  const { review } = props;
  return (
    <Grid item xs={12} sm={12} md={6} lg={4} style={{ padding: "5px" }}>
      <Grid
        container
        direction="column"
        style={{
          backgroundColor: "white",
          border: "1px solid lightgrey",
          borderRadius: "10px",
        }}
      >
        <Grid item container>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={5}
          >
            <img
              src={review.userPic}
              alt="user_img"
              style={{ borderRadius: "15px", margin: "15px 10px" }}
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            xs={7}
            justifyContent="flex-start"
            alignItems="center"
            style={{ paddingTop: "20px", paddingRight: "15px" }}
          >
            <Grid item>
              <Typography
                variant="subtitle1"
                sx={style_reviwerName}
                align="center"
              >
                {review.userName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ color: "#47C4FF" }}>
                {review.place}
              </Typography>
            </Grid>
            <Grid item>
              <Rating value={review.rating} max={review.rating} readOnly />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Typography
            variant="body1"
            gutterBottom
            align="justify"
            sx={{ color: "#5e5c90" }}
          >
            {review.review}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            padding: "10px 15px",
          }}
        >
          <Grid item display="flex">
            <Typography variant="caption" sx={{ color: "#5e5c90" }}>
              {review.tagName}
            </Typography>
          </Grid>
          <Grid item display="flex">
            <a
              href={review.externalUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: "1rem",
                textDecoration: "none",
                color: "#47C4FF",
              }}
              onClick={() => {
                sendEvent(`reviewcard_readmore_click`, {
                  tag_name: review.tagName,
                });
              }}
            >
              Read more
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const Reviews = () => {
  const isMobile = window.innerWidth <= 960;
  const pageSize = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [pageItemPositions, setPageItemPositions] = useState({
    lowerPosition: 0,
    upperPosition: 0,
  });

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    let upperPosition = value * pageSize - 1;
    let lowerPosition = (value - 1) * pageSize;
    setPageItemPositions({
      lowerPosition,
      upperPosition,
    });
  };

  useEffect(() => {
    sendEvent(`reviews_page_load`);
    let remainder = ReviewItems.length % pageSize;
    let quotient = (ReviewItems.length - remainder) / pageSize;
    if (remainder > 0) {
      setPageCount(quotient + 1);
    } else {
      setPageCount(quotient);
    }

    let upperPosition = currentPage * pageSize - 1;
    let lowerPosition = (currentPage - 1) * pageSize;
    setPageItemPositions({
      lowerPosition,
      upperPosition,
    });
  }, [currentPage]);

  return (
    <Container>
      <Helmet>
        <title>
          FirstCry PlayBees Reviews | Approved by Teachers & Parents
        </title>
        <meta
          name="keywords"
          content="blogger reviews, product reviews, app reviews, top mom bloggers, mom bloggers usa, indian mom bloggers, 5 star, app ratings, teachers approved, mom bloggers"
        />
        <meta
          name="description"
          content="Preschool educational app for kids that has 1000+ toddler learning games, baby songs, nursery rhymes and kindergarten story books for reading children!"
        />
        <meta name="author" content="FirstCry PlayBees" />
      </Helmet>
      <Typography
        variant="h3"
        gutterBottom
        sx={isMobile ? style_pageTitle_mobile : style_pageTitle}
      >
        Reviews
      </Typography>
      <Box sx={{ flexGrow: 1, paddingBottom: "15px" }}>
        <Grid
          container
          alignItems="stretch"
          spacing={1}
          style={{ margin: 0, width: "100%" }}
        >
          {ReviewItems.filter((item, index) => {
            if (
              index >= pageItemPositions.lowerPosition &&
              index <= pageItemPositions.upperPosition
            ) {
              return true;
            } else {
              return false; // skip
            }
          }).map((item, index) => {
            return <FormRow review={item} key={index} />;
          })}
        </Grid>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isMobile ? (
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              color="primary"
              siblingCount={0}
              boundaryCount={1}
            />
          ) : (
            <Pagination
              count={pageCount}
              page={currentPage}
              onChange={handlePageChange}
              showFirstButton
              showLastButton
              color="primary"
            />
          )}
        </div>
      </Box>
    </Container>
  );
};

export default Reviews;
