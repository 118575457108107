import { Grid, Typography } from "@mui/material";
import React from "react";

const Review = (props) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid
        item
        lg={6}
        xl={6}
        sx={{
          position: "relative",
          textAlign: "right",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${props.reviewerPhoto})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
          }}
        >
          <img
            src="images/home/reviews/reviewer_frame.png"
            alt="PlayBees Reviewer"
            style={{ color: "transparent" }}
          />
        </div>
      </Grid>

      <Grid
        item
        lg={6}
        xl={6}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <Grid
          item
          lg={2}
          xl={2}
          container
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <img
            src="images/home/reviews/starting_double_quote.png"
            alt="PlayBees App"
            style={{ marginTop: "80px", width: "75%", height: "auto" }}
          />
        </Grid>
        <Grid
          item
          lg={8}
          xl={8}
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: "left",
                fontSize: "2.6rem",
                fontFamily: `Jelly !important`,
                color: "#5e5c90",
                letterSpacing: "0px",
              }}
            >
              Recommended By
            </Typography>
            <Typography
              variant="h3"
              gutterBottom
              sx={{
                textAlign: "left",
                fontSize: "2.6rem",
                fontFamily: `Jelly !important`,
                color: "#5e5c90",
                letterSpacing: "0px",
              }}
            >
              Moms Worldwide
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ marginTop: "10px", color: "#5e5c90" }}
              textAlign="left"
            >
              {props.reviewText}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontSize: "2rem", marginTop: "20px", color: "#5e5c90" }}
            >
              {props.reviewerName}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          lg={2}
          xl={2}
          container
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          <img
            src="images/home/reviews/ending_double_quote.png"
            alt="PlayBees App"
            style={{
              marginBottom: "120px",
              marginLeft: "-50px",
              width: "75%",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Review;
