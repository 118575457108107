import React from "react";
import { Typography } from "@mui/material";

const FooterSection = () => {
  return (
    <div style={{ padding: "5px", height: "auto" }}>
      <Typography
        align="center"
        variant="h6"
        style={{ flexGrow: 1, fontSize: "15px", color: "#5e5c90" }}
      >
        © 2010-2024 www.FirstCry.com. All rights reserved.
      </Typography>
    </div>
  );
};

export default FooterSection;
