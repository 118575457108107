import { Container, Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import "./../CommonStyle.css";
import DownloadSection from "./../DownloadSection";
import FooterSection from "./../FooterSection";
import { sendEvent } from "./../../Config/AnalyticsConfig";
import { Helmet } from "react-helmet";
import { style_pageTitle, style_pageTitle_mobile } from "../CommonStyle";
import parse from "html-react-parser";

const Privacy = (props) => {
  const isMobile = window.innerWidth <= 960;

  useEffect(() => {
    sendEvent(`privacy_page_load`);
  }, []);

  const mobileView = props.match
    ? props.match.params.viewfor === "mobile"
      ? true
      : false
    : false;

  const ssoPageView = props.match
    ? props.match.params.viewfor === "ssopage"
      ? true
      : false
    : false;

  const onBackButtonClick = () => {
    if (mobileView) {
      window.location.href = "uniwebview://close?result=GoToUnity";
    } else {
      window.history.go(-1);
    }
  };

  const getMobileHeader = () => {
    if (mobileView || ssoPageView) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ height: "100px" }}
        >
          <Grid
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            item
          >
            <img
              src="/images/logos/playbees_logo.png"
              alt="playbees logo"
              width="70%"
            />
          </Grid>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            item
          >
            <img
              src="/images/faq/back_button_green.png"
              width="30%"
              onClick={onBackButtonClick}
              alt="Back Button"
            />
          </Grid>
        </Grid>
      );
    } else {
      return "";
    }
  };

  const getEmailLink = () => {
    if (mobileView || ssoPageView) {
      return "playbees@firstcry.com";
    } else {
      return (
        <a
          href="mailto:playbees@firstcry.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <u>playbees@firstcry.com</u>
        </a>
      );
    }
  };

  const getPlayBeesLink = () => {
    if (mobileView || ssoPageView) {
      return "https://playbees.firstcry.com";
    } else {
      return (
        <a
          href="https://playbees.firstcry.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://playbees.firstcry.com
        </a>
      );
    }
  };

  const getCoppaLink = () => {
    if (mobileView || ssoPageView) {
      return "Children's Online Privacy Protection Act (COPPA)";
    } else {
      return (
        <a
          href="https://en.wikipedia.org/wiki/Children%27s_Online_Privacy_Protection_Act"
          target="_Blank"
          rel="noopener noreferrer"
          onClick={() => {
            sendEvent(`coppa_click`);
          }}
        >
          Children's Online Privacy Protection Act (COPPA)
        </a>
      );
    }
  };

  return (
    <Container
      className={mobileView || ssoPageView ? "viewfor-parent" : ""}
      sx={{ color: "#5e5c90" }}
    >
      <Helmet>
        <title>Privacy Policy | FirstCry PlayBees - Kids Learning Games</title>
        <meta
          name="keywords"
          content="educational, baby songs, baby games, kids games, toddler learning games, story book, read stories, puzzle, memory games, preschool, kindergarten, nursery rhymes"
        />
        <meta
          name="description"
          content="Preschool educational app for kids that has 1000+ toddler learning games, baby songs, nursery rhymes and kindergarten story books for reading children!"
        />
        <meta name="author" content="FirstCry PlayBees" />
      </Helmet>
      {getMobileHeader()}
      <Typography
        variant="h3"
        gutterBottom
        sx={
          mobileView || ssoPageView
            ? { ...style_pageTitle_mobile, marginTop: "0px" }
            : isMobile
            ? style_pageTitle_mobile
            : style_pageTitle
        }
      >
        {mobileView || ssoPageView || isMobile
          ? parse(`Privacy Policy & <br /> Terms of Use`)
          : `Privacy Policy & Terms of Use`}
      </Typography>
      <Typography variant="body1" gutterBottom>
        Effective Date: July 14<sup>th</sup>, 2020
      </Typography>
      <br />
      <Typography variant="body1" gutterBottom align="justify">
        This Privacy Policy relates solely to the information collection and use
        practices of our web site located at "{getPlayBeesLink()}" and our
        FirstCry PlayBees applications (collectively referred to as “Website”).
        We recognize that many visitors and users of this Website are concerned
        about the information they provide to us and how we treat that
        information. This Privacy Policy, which may be updated from time to
        time, has been developed to address those concerns.
      </Typography>
      <br />
      <Typography
        variant="body1"
        gutterBottom
        style={{ fontWeight: "600" }}
        align="justify"
      >
        BY ACCESSING OR USING OUR WEBSITE OR BY PROVIDING YOUR INFORMATION, YOU
        HEREBY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE
        BOUND BY ALL THE TERMS OF THIS PRIVACY POLICY AND OUR WEBSITE TERMS OF
        USE. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE EXIT THIS PAGE AND DO
        NOT ACCESS OR USE THE WEBSITE.
      </Typography>
      <br />
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className="typography-h6"
      >
        ABOUT THE COMPANY AND IT’S PRIVACY POLICY
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Brainbees Solutions Private Limited (hereinafter referred to as the
        “Company” or “We”) values their customers and their trust. We follow the
        ethical approach of conducting business, collecting, using and
        maintaining confidentiality of all the information collected from its
        customers.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We value the privacy of both the parents/ legal guardians as well as the
        children who use the Website (hereinafter referred to as “You”) and
        strictly adhere to the ethical standards in protecting any information
        provided by you.
      </Typography>
      <br />
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className="typography-h6"
      >
        PRIVACY PRINCIPLES WE ABIDE BY
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <ul style={{ listStyleType: "none", paddingLeft: "20px" }}>
          <li>
            <b>a.</b> We’re deeply committed to creating a safe and secure
            online environment for your child.
          </li>
          <li>
            <b>b.</b> We do not sell any personal information to third parties.
            Our mission is to provide early learning to your kid in the best way
            possible.
          </li>
          <li>
            <b>c.</b> We strive to provide you with access to and control over
            the information you give us, and we take the protection of your
            information very seriously.
          </li>
          <li>
            <b>d.</b> We take extra precautions for our young learners,
            including restricting child accounts to automatically block features
            that would allow a child to post or disclose personal information.
          </li>
          <li>
            <b>e.</b> We do not display advertising posts of third parties on
            Apps. Our mission is to provide you with a better learning
            experience, not to sell you products.
          </li>
        </ul>
      </Typography>
      <br />
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className="typography-h6"
      >
        CHANGES TO PRIVACY POLICY
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We review the Policy from time to time, and we may make periodic changes
        to the Policy in connection with that review. The revisions in the
        Policy will be effective immediately upon being posted on the Website.
        Therefore, you may wish periodically would review this page to make sure
        you have the latest version. Your continued use of the Website after the
        effectiveness of such revisions will constitute your acknowledgment and
        acceptance of the terms of the revised Privacy Policy. The use of the
        information we collect is always subject to the Privacy Policy that is
        in effect at the time when this information is collected.
      </Typography>
      <br />
      <Typography
        variant="h6"
        gutterBottom
        component="div"
        className="typography-h6"
      >
        PERSONAL & NON-PERSONAL DATA COLLECTED AND THEIR USE
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Personal Data: Application opens; Application updates; device
        information; first launches; geography/region; In-app purchases; number
        of sessions; number of Users ; operating systems; session duration;
        unique device identifiers for advertising (Google Advertiser ID or IDFA,
        for example); Usage Data.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        1. Google Analytics for Firebase (Google LLC)
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Google Analytics for Firebase or Firebase Analytics is an analytics
        service provided by Google LLC. In order to understand Google's use of
        Data, consult Google's partner policy. Firebase Analytics may share Data
        with other tools provided by Firebase, such as Crash Reporting,
        Authentication, Remote Config or Notifications. The User may check this
        privacy policy to find a detailed explanation about the other tools used
        by the Owner. This Application uses identifiers for mobile devices and
        technologies similar to cookies to run the Firebase Analytics service.
        Users may opt-out of certain Firebase features through applicable device
        settings, such as the device advertising settings for mobile phones or
        by following the instructions in other Firebase related sections of this
        privacy policy, if available. Personal Data collected: Application
        opens; Application updates; device information; first launches;
        geography/region; In-app purchases; number of sessions; number of Users
        ; operating systems; session duration; unique device identifiers for
        advertising (Google Advertiser ID or IDFA, for example); Usage Data.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        2. The Purposes of Processing
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        The Data concerning the User is collected to allow the Owner to provide
        its Services, as well as for the following purposes: Tag Management,
        Analytics and Infrastructure monitoring. Users can find further detailed
        information about such purposes of processing and about the specific
        Personal Data used for each purpose in the respective sections of this
        document.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        3. Types of Data Collected
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Among the types of Personal Data that this Application collects, by
        itself or through third parties, there are: Cookies; Usage Data; device
        information; number of sessions; In-app purchases; Application opens;
        first launches; unique device identifiers for advertising (Google
        Advertiser ID or IDFA, for example); geography/region; number of Users ;
        session duration; Application updates; operating systems. Complete
        details on each type of Personal Data collected are provided in the
        dedicated sections of this privacy policy or by specific explanation
        texts displayed prior to the Data collection. Personal Data may be
        freely provided by the User, or, in case of Usage Data, collected
        automatically when using this Application. Unless specified otherwise,
        all Data requested by this Application is mandatory and failure to
        provide this Data may make it impossible for this Application to provide
        its services. In cases where this Application specifically states that
        some Data is not mandatory, Users are free not to communicate this Data
        without consequences to the availability or the functioning of the
        Service. Users who are uncertain about which Personal Data is mandatory
        are welcome to contact the Owner. Any use of Cookies – or of other
        tracking tools – by this Application or by the owners of third-party
        services used by this Application serves the purpose of providing the
        Service required by the User, in addition to any other purposes
        described in the present document and in the Cookie Policy, if
        available.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        4. The Service is directed to children under the age of 13
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        "As creators of kids apps, we adhere to The {getCoppaLink()} When it
        comes to the collection of personal information from children under the
        age of 13 years old, the Children's Online Privacy Protection Act
        (COPPA) puts parents in control. The Federal Trade Commission, United
        States' consumer protection agency, enforces the COPPA Rule, which
        spells out what operators of websites and online services must do to
        protect children's privacy and safety online." This Application is
        directed to children under the age of 13. It doesn't require the child
        to disclose any more information than is reasonably necessary to
        participate in its activity. Parents may review the personal information
        about their child collected by this Application - if any - outlined in
        the rest of this policy, Parents may also agree to the collection and
        use of their child’s information, but still not allow disclosure to
        third parties, unless disclosure is integral to the Service.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        COLLECTION OF INFORMATION
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        1. Personal or Sensitive Personal Information
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We obtain the information you provide when you download and register for
        the Website. You can use many features of the app without registering
        for an account or providing any other personal information. When you
        register with us you generally provide (a) your name, age, email
        address, location, phone number, password and your child’s/ wards’
        details such as name, age, birthdate, gender, class, educational/
        edutainment interests; (b) information you provide us when you contact
        us for help; (c) information you provide while using the Website,
        posting doubts,. The said information collected shall be categorized as
        “Personal Information” or “Sensitive Personal Information” as defined
        under the Information Technology (Reasonable Security Practices and
        Procedures and Sensitive Personal Data or Information) Rules, 2011 and
        shall be collectively referred to as “Personally Identifiable
        Information”. Any portion of the Personally Identifiable Information
        containing personal data relating to you or your child provided by you
        shall be deemed to be given with your or the consent of the child’s
        parents/ legal guardian.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        2. Information Collected Involuntarily
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        If you decide to register through or otherwise grant access to a
        third-party social networking or integrated service (“Integrated
        Service”), such as Facebook, Google, or similar single sign-on service,
        by default we may also collect Personally Identifiable Information that
        is already associated with your Integrated Service account. You may also
        have the option of sharing additional information with us through an
        Integrated Service, as controlled through your settings on that
        Integrated Service. If you choose to provide such information, during
        registration or otherwise, we will treat the information as Personally
        Identifiable Information and will treat it in the ways described in this
        Policy. The Website may collect certain information automatically,
        including, but not limited to, the type of device you use, your devices
        unique device ID, the IP address of your device, your operating system,
        the type of internet browsers you use and information about the way you
        use the Website. As is true of most mobile applications, we also collect
        other relevant information as per the permissions that you provide.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        3. Location information
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We may collect and use information about your location (such as your
        country, city) only after seeking permission or infer your approximate
        location based on your IP address in order to provide you with tailored
        content for your region.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        4. Use of Collected Information
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We use Personally Identifiable Information to enhance the operation of
        the Website, improve our marketing and promotional efforts, update and
        enhance the features of our Website, analyze Website use, improve our
        product offerings, to personalize your experience, know your
        preferences, interests, learning patterns, and mind development, analyse
        recent trends in edutainment and to understand how our users as a group
        use the services and resources provided on our Website and to provide
        you a better experience. The collected information can also be used to
        expedite process or restore your transactions across different devices
        or to send periodic emails regarding other apps and services. In order
        to personalize the experience of the users, we may use the individual
        data and their learning or interest patterns and may provide
        personalized features on the Website. We may use your Personally
        Identifiable Information to communicate with you about your account and
        respond to inquiries all relating to the usage of the Website.
        Disclosure of Personally Identifiable Information shall be permitted as
        required by law, order, decree by any government authority, to access
        any fraud or security purposes or technical issues, as required by our
        trusted service providers working in collaboration with us who have
        agreed to adhere to the rules set forth in this Policy and to our
        employees on a ‘need to know’ basis.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        MARKETING & ONLINE ADVERTISING
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We do not let third parties, including plug-ins collect Personally
        Identifiable Information from you or your children. Even the In-App
        Purchases or Subscription Service are accessible only via the parental
        gate. Only after you enter the correct answer to the parental question,
        the access to the parent page will be granted.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        There are no advertisements or links in our Apps which may direct the
        user to any third parties’ page. However, we can cross- promote our
        other apps/ services on the Apps. Any app-related third-party links such
        as social media pages, support and website are only accessible beyond
        the parental gate and child lock buttons.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        UPDATING INFORMATION
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        You will have the ability to access and edit the Personally Identifiable
        Information you provide us. You may change any of your Personally
        Identifiable Information by accessing through your login and password.
        We request you to promptly update your Personally Identifiable
        Information if it changes.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We will not remove content or information that we may be required to
        retain under applicable laws. We adopt reasonable security measures to
        protect your password from being exposed or disclosed to anyone.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        COOKIES
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        "Cookies" are small pieces of information that are stored by your
        browser on your computer's hard drive. The use of cookies is very common
        on the Internet and our Website's use of cookies is like that of other
        reputable online companies. Cookies will be used to customize your
        experience with the Website. We use cookies to save you time while using
        the Website, helps us identify who you are, and track and target user
        interests in order to provide a customized experience. Cookies also
        allow us to collect Non-Personally Identifiable Information from you,
        like which pages you visited and what links you clicked on. Use of this
        information helps us to create a more user-friendly experience for all
        visitors. Most browsers automatically accept cookies, but you may be
        able to modify your browser settings to decline cookies. Please note
        that if you decline or delete these cookies, some parts of the Website
        may not work properly. Additionally, you may encounter "cookies" or
        other similar devices on certain pages of the Website that are placed by
        third parties. We do not control the use of cookies by third parties.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        OTHER TRACKING DEVICES
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We may use other industry standard technologies like pixel tags and web
        beacons to track your use of our Website pages and promotions, or we may
        allow our thirdparty service providers to use these devices on our
        behalf. Pixel tags and web beacons are tiny graphic images placed on
        certain pages on our Website, or in our emails that allow us to
        determine whether you have performed a specific action. When you access
        these pages or open or click an email, pixel tags and web beacons
        generate a Non-Personally Identifiable notice of that action. Pixel tags
        allow us to measure and improve our understanding of visitor traffic and
        behaviour on our Website, as well as give us a way to measure our
        promotions and performance. We may also utilize pixel tags and web
        beacons provided by our affiliates for the same purposes.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        SECURITY
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We are concerned with safeguarding the Personally Identifiable
        Information provided by you. We strive to provide the maximum
        confidentiality in terms of physical, electronic and technical measures
        to maintain the privacy of the Information. We share the Personally
        Identifiable Information with our employees solely on a ‘need to know’
        basis. However, no data transmission over the Internet can be guaranteed
        to be completely secure. Accordingly, we cannot ensure or warrant the
        security of any information that you transmit to us, so you should do so
        at your own risk.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        PERMISSIONS
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Our Website does typically require storage permissions, for the purpose
        downloading additional content for the Website, providing links to the
        app store entry of our other apps and to collect anonymous usage
        information for analytics purposes.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We strictly adhere to the ethical practice of data privacy and do not
        sell, trade or rent your information unless we have been expressly
        authorized by you either in writing or electronically. We may at times
        provide aggregate statistics about our customers, traffic patterns, and
        related site information to reputable third parties, however, this
        information when disclosed will be in an aggregate form and does not
        contain any of your Personally Identifiable Information.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        CHILDREN'S PRIVACY POLICY
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We recognize the need to provide additional security for the Personally
        Identifiable Information of the children on our Website. We keep you
        updated regarding the Personally Identifiable Information required for
        your children, its usage, obtaining consent from parents/ legal
        guardian.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        YOUR CONSENT
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        The information collected by us at the time of registration and during
        the usage of the Website, are expressly consented by you to our
        collection, storage, disclosure and handling as set forth in this Policy
        now and as amended by us from time to time. It shall be assumed that the
        collection, storage and further process of activities that take place in
        India shall be governed as per the applicable data protection laws of
        India. You are of eighteen (18) years or above once you accept the
        policies. In case of you being below 18 years of age, please do not
        share any information with us without the guidance and consent of your
        parents or legal guardian acting on your behalf.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        TERMS OF USE
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        This below is an electronic record in terms of Information Technology
        Act, 2000 and rules there under, as applicable and the amended
        provisions pertaining to electronic records in various statutes as
        amended by the Information Technology Act, 2000. This electronic record
        is generated by a computer system and does not require any physical or
        digital signatures. The Terms of Use{" "}
        <label className="typography-body1-highlight">(“Terms”)</label> and the
        Privacy Policy together creates a legally binding agreement{" "}
        <label className="typography-body1-highlight">(“Agreement”)</label>{" "}
        between Brainbees Solutions Private Limited and the User.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Please read the following terms and conditions very carefully as your
        use of service is subject to your acceptance of and compliance with the
        following terms and conditions.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        GENERAL INFORMATION
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        The edutainment site at playbees.firstcry.com is built by Brainbees
        Solutions Private Limited as a platform for kids to provide them with
        e-learning services, activities, products,{" "}
        <label className="typography-body1-highlight">(“Services”)</label>{" "}
        through Firstcry Playbees’ applications{" "}
        <label className="typography-body1-highlight">(“App”)</label>{" "}
        collectively referred to as{" "}
        <label className="typography-body1-highlight">“Website”</label>. Your
        use of the Website and Services are governed by the following Terms. For
        the purpose of these Terms, wherever the context so requires{" "}
        <label className="typography-body1-highlight">"You"</label> or{" "}
        <label className="typography-body1-highlight">"User"</label> shall mean
        parents/ legal guardians/ any natural or legal person who has agreed to
        become a member of the Website by providing Registration Data (as
        defined below) while registering on the Website. Brainbees Solutions
        Private Limited shall hereinafter, wherever the context so requires, be
        referred to as the{" "}
        <label className="typography-body1-highlight">“Company”</label> or{" "}
        <label className="typography-body1-highlight">“We”</label>.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        By using the Website, you agree to the Terms.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        1.1. We reserve the right at all times to discontinue or modify the
        Terms of Use and/or our Privacy Policy as we deem necessary or desirable
        without any prior notification and such modifications would be effective
        immediately. If any changes to the Terms is found invalid, void, or for
        any reason unenforceable, that change is severable and does not affect
        the validity and enforceability of any remaining changes and the
        remainder of the Terms. We suggest that you read our Terms of Use and
        Privacy Policy from time to time to stay informed. Any use of the
        Website after such modifications would be deemed to constitute
        acceptance of the modified terms by the User. If you do not agree to any
        changes, you must not use the Service and/ or Website.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        1.2. We shall have the discretion to modify the Services at any time.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        ACCOUNT AND REGISTRATION
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Membership Eligibility
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        An option of registration at the Website shall be available only to
        persons who can form legally binding contracts under applicable law.
        Persons who are "incompetent to contract" within the meaning of the
        Indian Contract Act, 1872 including un-discharged insolvents etc. are
        not eligible to register and accept the Terms of the Website. The
        Company strictly adheres to the protocol of registration and acceptance
        of the Terms by the Parents/ Legal Guardians on behalf of their minors.
        As a minor if you wish to use our products or Services, such use shall
        be made available to you by your legal guardian or parents, who has
        agreed to these Terms. In the event a minor utilizes the Website and/ or
        Services, it is assumed that he/she has obtained the consent of his/ her
        legal guardian or parents and such use is made available by the legal
        guardian or parents. The Company will not be responsible for any
        consequence that arises as a result of misuse of any kind of the Website
        or any of Services that may occur by virtue of any person including a
        minor registering for the Services provided. We shall have a right to
        terminate the Services if we come to know that the registration has been
        executed by any minor without the consent of their parents or legal
        guardians.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Access to Website
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Users may access the Website and App by downloading and installing the
        App by way of free downloads, trials or for subscription fees in
        computer/ mobile/ tablets/ other similar hardware devices. We will not
        be responsible for any damage caused to the device including any loss
        claimed to be resulted from the usage of the Website. The free downloads
        and trials will act like a demonstration for Users and shall grant
        limited access to Users for a limited period of time. Post
        demonstration, Users may subscribe for the Services for a defined period
        in exchange for a subscription fee{" "}
        <label className="typography-body1-highlight">(“Fee”)</label> as charged
        by the Company.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Creation and Maintenance of Accounts
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        If you do not have a Firstcry Playbees or a Firstcry.com account, you
        will need to establish an account with __Firstcry Playbees or
        FirstCry.com______ to access all features of this service
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Basis the eligibility, you shall be required to register with us by
        creating an account and furnishing few personal information about
        yourself during the registration process{" "}
        <label className="typography-body1-highlight">
          ("Registration Data")
        </label>
        . It is your responsibility to keep the details of the account and
        password confidential and to take responsibility for all activities that
        occur under your account or password. We shall not be liable to any
        person for any loss or damage which may arise as a result of any failure
        by the User to protect the account details or the password. In case of
        any suspected breach, please contact us immediately. If we have reasons
        to believe that there is likely to be a breach of security or misuse of
        the Website, we may require you to change your password or we may
        suspend your account without there being any liability on us.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        You also agree to:
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        1. Provide true, accurate, current and complete information of
        Registration Data;
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        2. Maintain and promptly update the Registration Data to keep it true,
        accurate, current and complete. If you provide any information that is
        untrue, inaccurate, incomplete, or not current or if we have reasonable
        grounds to suspect that such information is untrue, inaccurate, not
        current or not in accordance with the Terms, we will have the right to
        indefinitely suspend or terminate your membership and refuse to provide
        you with access to the Website.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        SUBSCRIPTION FEES FOR USAGE OF THE SERVICES
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Payment of the Fees
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        User shall be granted a right to access and use the Website to avail the
        Services provided in it on the payment of the periodic subscription
        plan. Each App may have its own services and respective subscription
        payment plan. By agreeing to subscribe to the App, you agree to pay the
        Fee displayed within the App for the specific period granted. Users are
        also required to agree to the terms and conditions of the specific
        payment method chosen to subscribe for either of the plans. All Users
        will be automatically charged the Fee- monthly, half yearly or yearly
        basis the plan they have opted for. Users can also pay one time fee and
        unlock the app for lifetime access
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        The Fee structure of each plan may be amended from time to time by us.
        You can change and opt for any other plans at any time. If you do not
        agree to price change, you may cancel your subscription from the
        respective store. Your continued use of our Website and Services after
        the notice of change in Fee constitutes your agreement to the new fees.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Payment Disputes
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        For any payment disputes, please write to us at {getEmailLink()}
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        License and Accessibility
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        As Users, you are granted a non-exclusive, non-transferable, revocable
        and limited license to access and use our Website/ Contents (as defined
        below). We may terminate this license at any time for any reason,
        whatsoever. You may use the Website/Contents for your own personal,
        non-commercial purposes. No part of the Website/Contents may be
        reproduced in any form electronic or physical. You may not use, copy,
        emulate, clone, rent, lease, sell, modify, decompile, disassemble,
        reverse engineer or transfer the Website, Contents or any portion
        thereof. You may not use any automated means or form of scraping or data
        extraction to access, query or otherwise collect information from the
        Website/Contents except as expressly permitted by us.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We reserve rights not explicitly granted in these Terms. You may not use
        any device, software or routine to interfere or attempt to interfere
        with the proper working of the Website/Contents. As a User, your license
        is provided solely for access and usage of Website/Contents in exchange
        for a Fee.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Website and App Permissions
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        When you use the Website/ App, you may grant certain permissions to us
        for your device, in furtherance of which we may contact you through SMS/
        calls/ e-mails as required in order to intimate or notify you about any
        additional features of our Services. By registering yourself, you are
        expressly granting us, our employees, partner associates, permission to
        contact you for any promotion or information with regard to the
        App/Services. We shall not be liable in any way in case the User’s
        contact is registered with ‘Do not Call/ Do Not Disturb’ database. Your
        mobile device will provide you the information about these permissions.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Free or Promotional Trial Subscription
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Sometimes, we offer various trials or other promotional subscription
        services to certain Users subject to the Terms except as otherwise
        stated in the promotional offers. Trial members may at any time through
        their account choose not to purchase subscription/trial services at the
        end of the trial period.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        CONTENTS AND ITS GUARANTEE
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        The Contents of the Services will include materials like games, puzzles,
        rhymes, pictures, videos and other activities{" "}
        <label className="typography-body1-highlight">(“Contents”)</label> for
        kids from 0 to 8 years old. The Contents may be modified, amended and
        upgraded from time to time with new features. We, at the Company, shall
        always strive to offer the updated and accurate Services, nevertheless,
        inadvertent errors may occur sometimes. We do not guarantee the
        materials to be accurate, complete and useful or otherwise. We disclaim
        any responsibility for any error/ inaccuracies contained in the
        App/Contents. The usefulness of the App/ Contents shall solely depend on
        the individual interest and learning ability of your kids.
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        OTHER TERMS
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Intellectual Property Rights
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        Everything located on or in this Website and in the app is our exclusive
        property and can be used solely with express permission of the copyright
        and/or trademark owner. ANY COPYING, DISTRIBUTING, TRANSMITTING,
        POSTING, LINKING, DEEP LINKING, OR OTHERWISE MODIFYING OF THIS WEBSITE
        OR ANY OF THE MICROSITES WITHOUT OUR EXPRESS WRITTEN PERMISSION IS
        STRICTLY PROHIBITED. Any violation may result in a copyright, trademark
        or other intellectual property right infringement that may subject the
        User to civil and / or criminal penalties.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        This Website contains copyrighted material, trademarks and other
        proprietary information, including, but not limited to, text, software,
        rhymes, games, activities, stories, text, graphics, images, audio clips,
        music, videos and digital downloads and the entire contents of Website
        protected by copyright. We own a copyright in the selection,
        coordination, arrangement and enhancement of Contents, as well as in the
        content original to it. User may not modify, publish, transmit,
        participate in the transfer or sale, create derivative works, or in any
        way exploit, any of the content, in whole or in part. User may download
        / print / save copyrighted material for User's personal use only. Except
        as otherwise expressly stated under copyright law, no copying,
        redistribution, retransmission, publication or commercial exploitation
        of downloaded material without our express permission and the copyright
        owner is permitted. If copying, redistribution or publication of
        copyrighted material is permitted, no changes in or deletion of author
        attribution, trademark legend or copyright notice shall be made. User
        acknowledges that he/she/it does not acquire any ownership rights by
        downloading copyrighted material. Trademarks, service marks, logos and
        button icons that are located within or on the Website or app shall not
        be deemed to be in the public domain but rather the exclusive property
        of owners, unless such Website is under license from the Trademark owner
        thereof in which case such license is for our exclusive benefit and use,
        unless otherwise stated.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        The foregoing provisions of this clause apply equally to and are for the
        benefit of the Company, its subsidiaries, affiliates and its third party
        content providers and licensors and each shall have the right to assert
        and enforce such provisions directly or on its own behalf.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Termination by Us
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        We may terminate your subscription Services at our discretion without
        notice. Without limiting the foregoing, we shall have, in our sole
        discretion, the right to terminate an account of any User in the event
        of any unacceptable conduct or any breach of these Terms by the User. We
        can terminate your subscription Services or Account in case of any
        suspicion of fraud or misuse of the Services, or in cases of any other
        threat either to the Website or to interest of any other user. In such
        cases of termination, we shall not be liable to any User.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Indemnity
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        You agree to defend, indemnify and hold harmless the Company, its
        employees, directors, officers, agents and their successors and
        assignees from and against any and all claims, liabilities, damages,
        losses, costs and expenses, including attorney's fees, caused by or
        arising out of claims based upon your actions or inactions, which may
        result in any loss or liability to us or any third party including but
        not limited to breach of any warranties, representations or undertakings
        or in relation to the non-fulfillment of any of your obligations under
        these Terms or arising out of your violation of any applicable laws,
        regulations including but not limited to claim of libel, defamation,
        violation of rights of privacy or publicity, loss of service by other
        subscribers and infringement of intellectual property or other rights.
        This clause shall survive the expiry or termination of these Terms.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Limitation of Liability
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        The Website is provided without any warranties or guarantees and in an
        "As Is" condition. You must bear the risks associated with the use of
        the Website. We shall not be liable to any User for any damages, loss,
        injury, claim resulting from the use of the Services available on the
        Website. We shall not be responsible for any errors or omissions or for
        the results obtained from the use of such information or for any
        technical problems you may experience with the Website. To the fullest
        extent permitted under applicable law, we shall not be liable for any
        indirect, incidental, special, incidental, consequential or exemplary
        damages, arising out of or in connection with the Website, its Services
        or this User Agreement.
      </Typography>
      <Typography variant="subtitle1" className="typography-subtitle1">
        Site Security
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        You are prohibited from violating or attempting to violate the security
        of the Website, including, without limitation, (a) accessing data not
        intended for you or logging onto a server or an account which you are
        not authorized to access; (b) attempting to probe, scan or test the
        vulnerability of a system or network or to breach security or
        authentication measures without proper authorization; (c) attempting to
        interfere with service to any other user, host or network, including,
        without limitation, via means of submitting a virus to the Site,
        overloading, "flooding," "spamming," "mail bombing" or "crashing;" (d)
        sending unsolicited email, including promotions and/or advertising of
        products or services. Violations of system or network security may
        result in civil or criminal liability. We will investigate occurrences
        that may involve such violations and may involve, and cooperate with,
        law enforcement authorities in prosecuting users who are involved in
        such violations. You agree not to use any device, software or routine to
        interfere or attempt to interfere with the proper working of this
        Website or any activity being conducted on this Website. You agree,
        further, not to use or attempt to use any engine, software, tool, agent
        or other device or mechanism (including without limitation browsers,
        spiders, robots, avatars or intelligent agents) to navigate or search
        this Website other than the search engine and search agents available
        from the Website on this Website and other than generally available
        third party web browsers (e.g., Netscape Navigator, Microsoft Explorer).
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom className="typography-h6">
        MISCELLANEOUS
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <label className="typography-body1-highlight">1. Force Majeure:</label>{" "}
        Without prejudice to any other provision herein, we will not be liable
        for any loss, damage or penalty as a result of any delay in or failure
        to deliver or otherwise perform hereunder due to any cause beyond our
        control, including but not limited to the acts of the User, server
        issues, embargo or other governmental sanction affecting the conduct of
        business, fire, explosion, accident, theft, vandalism, riot, acts of
        war, strikes or other labor/employee difficulties, lockdown, lightning,
        flood, windstorm or other acts of God.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <label className="typography-body1-highlight">
          2. Entire Agreement:
        </label>{" "}
        The Terms, Privacy Policy and any operating rules for the Website
        constitute the entire agreement of the parties with respect to the
        subject matter hereof.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <label className="typography-body1-highlight">3. Waiver:</label> No
        waiver by either party of any breach or default hereunder is a waiver of
        any preceding or subsequent breach or default. The failure of either
        party to exercise any of its rights under this Agreement shall not be
        deemed a waiver or forfeiture of such rights or any other rights
        provided hereunder.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <label className="typography-body1-highlight">4. Headings:</label> The
        section headings used herein are for convenience only and shall be of no
        legal force or effect.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <label className="typography-body1-highlight">5. Severability:</label>{" "}
        If any provision of this Agreement is held invalid by a court of
        competent jurisdiction, such invalidity shall not affect the
        enforceability of any other provisions contained in this Agreement and
        the remaining portions of this Agreement shall continue in full force
        and effect.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <label className="typography-body1-highlight">6. Assignment:</label> You
        cannot assign or otherwise transfer your obligations under these Terms,
        or any right granted hereunder to any third party. The Company's rights
        under the Terms are freely transferable by the Company to any third
        parties without the requirement of seeking your consent.
      </Typography>
      <Typography variant="body1" gutterBottom align="justify">
        <label className="typography-body1-highlight">7. Governing Law:</label>{" "}
        This Agreement will be governed by laws of India, subject to the Courts
        in Pune, without reference to conflict of laws principles, if any.
      </Typography>

      {mobileView || ssoPageView ? "" : <DownloadSection />}

      {mobileView || ssoPageView ? <FooterSection /> : ""}
    </Container>
  );
};

export default Privacy;
