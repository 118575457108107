import { Container, Box, Tabs, Tab, Typography, Grid } from "@mui/material";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./../CommonStyle.css";
import { FaqItems } from "./FaqItems";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaAngleDown } from "react-icons/fa";
// import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import DownloadSection from "./../DownloadSection";
import { sendEvent } from "./../../Config/AnalyticsConfig";
import FooterSection from "../FooterSection";
import { Helmet } from "react-helmet";
import { style_pageTitle_mobile, style_pageTitle } from "../CommonStyle";
import "./Faq.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Faq = (props) => {
  const isMobile = window.innerWidth <= 960;
  const mobileView = props.match
    ? props.match.params.viewfor === "mobile"
      ? true
      : false
    : false;

  useEffect(() => {
    sendEvent(`faq_page_load`);
  }, []);

  const onBackButtonClick = () => {
    window.location.href = "uniwebview://close?result=GoToUnity";
  };

  const getMobileHeader = () => {
    if (mobileView) {
      return (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ height: "100px" }}
        >
          <Grid
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            item
          >
            <img
              src="/images/logos/playbees_logo.png"
              alt="playbees logo"
              width="70%"
            />
          </Grid>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            item
          >
            <img
              src="/images/faq/back_button_green.png"
              width="35%"
              onClick={onBackButtonClick}
              alt="Back Button"
            />
          </Grid>
        </Grid>
      );
    } else {
      return "";
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setExpanded(false);
    setValue(newValue);
  };

  const [expanded, setExpanded] = React.useState(false);

  const handleTabExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const getTabPanel = (data, dataIndex) => {
    return (
      <TabPanel
        value={value}
        index={dataIndex}
        key={dataIndex}
        sx={{ padding: "10px" }}
      >
        <div>
          {data.map((item, index) => {
            return (
              <Accordion
                expanded={expanded === index}
                onChange={handleTabExpand(index)}
                key={index}
              >
                <AccordionSummary expandIcon={<FaAngleDown />}>
                  <Typography
                    variant="subtitle1"
                    className={expanded === index ? "typography-subtitle1" : ""}
                    color={expanded === index ? "#a90170" : "#5e5c90"}
                  >
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography gutterBottom align="justify" color="#5e5c90">
                    {/* {ReactHtmlParser(item.answer)} */}
                    {parse(item.answer)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </TabPanel>
    );
  };

  return (
    <Container className={mobileView ? "viewfor-parent" : ""}>
      <Helmet>
        <title>Have any questions about FirstCry PlayBees App?</title>
        <meta
          name="keywords"
          content="educational, baby songs, baby games, kids games, toddler learning games, story book, read stories, puzzle, memory games, preschool, kindergarten, nursery rhymes"
        />
        <meta
          name="description"
          content="Preschool educational app for kids that has 1000+ toddler learning games, baby songs, nursery rhymes and kindergarten story books for reading children!"
        />
        <meta name="author" content="FirstCry PlayBees" />
      </Helmet>
      {getMobileHeader()}
      <Typography
        variant="h3"
        gutterBottom
        sx={
          mobileView
            ? { ...style_pageTitle_mobile, marginTop: "0px" }
            : isMobile
            ? style_pageTitle_mobile
            : style_pageTitle
        }
      >
        Frequently Asked Questions (FAQs)
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", color: "#5e5c90" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="PlayBees FAQ tabs"
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
          >
            {FaqItems.map((item, index) => {
              return (
                <Tab
                  label={item.tabTitle}
                  sx={{
                    color: "#5e5c90",
                  }}
                  key={index}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Box>
        {FaqItems.map((item, index) => {
          return getTabPanel(item.items, index);
        })}
      </Box>
      {mobileView ? "" : <DownloadSection />}

      {mobileView ? <FooterSection /> : ""}
    </Container>
  );
};

export default Faq;
