import React, { useState } from "react";
import "./AppItem.css"; // Import CSS file for styling
import { Card, Grid, Typography } from "@mui/material";
import _ from "lodash";

const AppItem = ({
  bannerImg,
  title,
  description,
  appIcon,
  playStoreUrl,
  appStoreUrl,
  cssClassName,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onOpenLinkInNewTabClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <Card
      sx={{
        maxWidth: 470,
        height: 550,
        borderRadius: 6,
        boxShadow: "0px 5px 10px 5px #efefef",
      }}
    >
      <div className="parent-container">
        <img src={bannerImg} alt={title} style={{ width: "100%" }} />

        <div
          className={`middle-container ${isHovered ? cssClassName : ""}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{ marginBottom: "10px" }}
            >
              <Grid item xs={2} md={2}>
                <img src={appIcon} alt={title} style={{ width: "100%" }} />
              </Grid>
              <Grid item sx={{ paddingLeft: "15px" }} xs={10} md={10}>
                <Typography
                  gutterBottom
                  variant="h5"
                  sx={{ marginBottom: "0", color: "black", fontWeight: 600 }}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="body2"
                align="justify"
                sx={{
                  color: "#5e5c90",
                  fontWeight: 500,
                  fontSize: "1rem",
                }}
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div className="bottom-container">
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item sx={{ display: "flex" }}>
              <img
                src="/images/apps/play_store_icon.png"
                alt="Play Store Icon"
                style={{ width: "72%", cursor: "pointer" }}
                onClick={() => onOpenLinkInNewTabClick(playStoreUrl)}
              />
            </Grid>
            {_.isEmpty(appStoreUrl) ? (
              ""
            ) : (
              <Grid item>
                <img
                  src="/images/apps/app_store_icon.png"
                  alt="App Store Icon"
                  style={{ width: "65%", cursor: "pointer" }}
                  onClick={() => onOpenLinkInNewTabClick(appStoreUrl)}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </Card>
  );
};

export default AppItem;
