import { Grid } from "@mui/material";
import React from "react";
import "./DownloadSection.css";
import { sendEvent } from "./../Config/AnalyticsConfig";

const DownloadSection = (props) => {
  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: "10px", marginBottom: "10px" }}
    >
      <Grid
        item
        container
        spacing={1}
        style={{ margin: 0, width: "100%" }}
        justifyContent="center"
      >
        <Grid item xs={12} sm={6} md={6} lg={6} className="appstore-grid-item">
          <img
            src="/images/logos/appstore_button.png"
            alt="FirstCry PlayBees App for iOS - iPhone and iPad"
            onClick={() => {
              sendEvent(props.appStoreEventName);
              openInNewTab(props.appStore);
            }}
            className="store-img"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className="playstore-grid-item">
          <img
            src="/images/logos/googleplay_button.png"
            alt="FirstCry PlayBees App for Android - Google PlayStore"
            onClick={() => {
              sendEvent(props.playStoreEventName);
              openInNewTab(props.playStore);
            }}
            className="store-img"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

DownloadSection.defaultProps = {
  playStore: `https://play.google.com/store/apps/details?id=com.firstcry.playbees.learn.grow.kids.preschool.toddler.numbers.abc.rhymes.games.stories.books`,
  appStore: `https://apps.apple.com/us/app/firstcry-playbees-kids-games/id1529402533`,
  playStoreEventName: `default_playstore`,
  appStoreEventName: `default_appstore`,
};

export default DownloadSection;
