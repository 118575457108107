export const ReviewItems = [
  {
    userName: `Nikita Nel`,
    place: `South Africa`,
    rating: 5,
    review: `We've been using PlayBees to build Madison's vocabulary & word recognition. She has great time learning about everything from sea animals to body parts. We love to see her make the real connections to words.`,
    externalUrl: `https://www.instagram.com/p/CTKuZVOqxPm/`,
    tagName: `@mama_of_a_wildflower_za`,
    userPic: `/images/reviews/nikita_nel.jpg`,
  },

  {
    userName: `Kathleen May Navarra`,
    place: `Philippines`,
    rating: 5,
    review: `I recently found that Ali, who is only 22 months is good at puzzles & pooping balloons. Puzzles is Ali'd favourite. She also likes activities, baby songs, rhymes and story books which are great for kids 0-6 years old!`,
    externalUrl: `https://www.instagram.com/p/CSvDCSoBfFs/`,
    tagName: `@mommykeiem`,
    userPic: `/images/reviews/kathleen_may_navara.jpg`,
  },

  {
    userName: `Charu Bhartia`,
    place: `India`,
    rating: 4,
    review: `PlayBees app helps kids learn alphabet, numbers, shapes, colours, fruits, vegetables and so much more! The app offers many flip books & read aloud story books for popular fairy tales & kids stories with morals.`,
    externalUrl: `https://www.instagram.com/p/CQi0dZ6FzFQ/`,
    tagName: `@twintelligent_mom`,
    userPic: `/images/reviews/Charu-Bhatia.jpg`,
  },

  {
    userName: `Puja Bhandari (Jain)`,
    place: `India`,
    rating: 4,
    review: `FirstCry PlayBees aap is best app I have come across for toddlers age 0-5 years old. One of the best ways to teach kids their first ABC's and 123 numbers is through fun games that are educational and entertaining!`,
    externalUrl: `https://www.instagram.com/p/CQf-WgMn57V/`,
    tagName: `@ipujachhajed`,
    userPic: `/images/reviews/Puja-Jain.jpg`,
  },

  {
    userName: `Leiann Cadywould`,
    place: `United Kindom`,
    rating: 5,
    review: `Lincoln has really improved in letter recognition & phonics with this app. It’s been brilliant for his brain development and recognition. You can set the age of your child for difficulty levels. We love it so far!`,
    externalUrl: `https://www.instagram.com/p/CQeEhSbMr0D/`,
    tagName: `@leiann.and.lincoln`,
    userPic: `/images/reviews/Leiann-Cadywould.jpg`,
  },

  {
    userName: `Christine Joy Pingol`,
    place: `Philippines`,
    rating: 5,
    review: `I have found an all in one educational app that has variety of activities that are fun. What I love about this app is they have mini-games, stories and rhymes. It teaches moral values and helps Mira to read!`,
    externalUrl: `https://www.instagram.com/p/CTU1Wa7BufB/`,
    tagName: `@momshietintinvlogs`,
    userPic: `/images/reviews/christine_joy_pingol.jpg`,
  },

  {
    userName: `Kanika Bhatia`,
    place: `India`,
    rating: 5,
    review: `Your little ones will surely enjoy learning with 1000+ games, rhymes, storybooks. I find it easy to navigate and like when my daughter listens to stories of her choice without my involvement. Best educational app!`,
    externalUrl: `https://www.instagram.com/p/CTeQgvSphaj/`,
    tagName: `@imperfectlyperfectmaa`,
    userPic: `/images/reviews/kanika_bhatia.jpg`,
  },
  {
    userName: `Kammille H. Maligaya`,
    place: `Philippines`,
    rating: 5,
    review: `Teach kids ABC's, numbers, first words with fun activities. Toddlers up-to 5 years age can learn to read stories, trace alphabet, colour animals! Lance's favourite game is the Easter eggs, fix the fruits & veggies!`,
    externalUrl: `https://www.instagram.com/p/CThlOC5hpI1/`,
    tagName: `@khamzyyy`,
    userPic: `/images/reviews/kammille_h_maligaya.jpg`,
  },
  {
    userName: `Annie Sharma`,
    place: `India`,
    rating: 5,
    review: `My baby has become fond of number games. She listens to 123 songs, repeats after it and tries to complete puzzle. She has started counting on her own. Her favourite is 'Jhonny, Jhonny, Yes Papa', 'Finger Song'!`,
    externalUrl: `https://www.instagram.com/p/CQim0bkMl82/`,
    tagName: `@babydigitlcare`,
    userPic: `/images/reviews/Annie-Sharma.jpg`,
  },
  {
    userName: `Anupreet Kaur`,
    place: `India`,
    rating: 4,
    review: `This great app is ads free & safe to use for kids. It helped me turn my kids screen time into learning time. It comprises of almost everything. I am happy that kids have started learning so many things so easily.`,
    externalUrl: `https://www.instagram.com/p/CQkuwhhrUs2/`,
    tagName: `@anu.mommyblogger`,
    userPic: `/images/reviews/Anupreet.jpg`,
  },
  {
    userName: `Sarah Mae Morales`,
    place: `Philippines`,
    rating: 5,
    review: `FirstCry PlayBees app is a very helpful app for preschoolers. It has nursery rhymes, abcs, 123, shapes, animals, colours, fables that kids love playing. My Daughter, Sam, really loves the stories & solar system.`,
    externalUrl: `https://www.instagram.com/p/CThcYQNJ_GU/`,
    tagName: `@mommysarahandsam`,
    userPic: `/images/reviews/sarah_mae_morales.jpg`,
  },
  {
    userName: `Khushbu`,
    place: `India`,
    rating: 4,
    review: `Viransh is learning English through this app. You can also change the age between 𝟬-𝟮, 𝟮-𝟰, 𝟰-𝟲 𝘆𝗲𝗮𝗿𝘀 𝗼𝗹𝗱 to tailor fit the content. Just search for PlayBees on Play Store/App Store to start learning.`,
    externalUrl: `https://www.instagram.com/p/CREBtIXljK_/`,
    tagName: `@babyandmomdiaries`,
    userPic: `/images/reviews/Khushbu.jpg`,
  },
  {
    userName: `Sarrah Jane`,
    place: `Philippines`,
    rating: 5,
    review: `It gives us more mom-and-daughter bonding time. I would sing along with her, read flip books, help her play games. We listen to stories together. She has just started learning and there is so much to explore!`,
    externalUrl: `https://www.instagram.com/p/CRBKuzrByFD/`,
    tagName: `@sarrahjove`,
    userPic: `/images/reviews/Sarrah-Jove.jpg`,
  },
  {
    userName: `Rituka Bagri`,
    place: `India`,
    rating: 4,
    review: `PlayBees has storybooks to teach kids how to read, learn pronunciations, spellings, word usage. There are character building stories to teach morals, good habits. For older kids, there is basic math, add, subtract!`,
    externalUrl: `https://www.instagram.com/p/CRD_cX_FWO4/`,
    tagName: `@bagrisaksham`,
    userPic: `/images/reviews/Rituka-Bagri.jpg`,
  },
  {
    userName: `Nidhi Agrawal`,
    place: `India`,
    rating: 5,
    review: `I love the graphics and picturesque of the app. My kid enjoys learning alphabet, watching rhymes, stories. He also loves the pop balloon game. It is available on android & iOS. Try this for kids early learning.`,
    externalUrl: `https://www.instagram.com/p/CRD2oC3Fllu/`,
    tagName: `@garg.aryaman`,
    userPic: `/images/reviews/Nidhi-Agrawal.jpg`,
  },
  {
    userName: `Reggiebel Buduan`,
    place: `Philippines`,
    rating: 5,
    review: `Things I love about FirstCry PlayBees app is It help kids to learn and grow, it is made for kids age 0-6 years old, it has parental locks, they have read aloud storybooks, It's easy to navigate, you should try!`,
    externalUrl: `https://www.instagram.com/p/CTaLDIvBKVp/`,
    tagName: `@buduandiaries`,
    userPic: `/images/reviews/reggiebel_buduan.jpg`,
  },
  {
    userName: `Lucy`,
    place: `United Kindom`,
    rating: 4,
    review: `So far we have watched humpty dumpy about a 100 times! Bright colourful, musical rhymes that toddlers love, along with books (Learn to read). There are many games that Oscar could manage to play on his own.`,
    externalUrl: `https://www.instagram.com/p/CPF_GQAFepy/`,
    tagName: `@mumof_3_life`,
    userPic: `/images/reviews/Lucy.jpg`,
  },
  {
    userName: `Mary Magdalene Mengullo`,
    place: `Philippines`,
    rating: 5,
    review: `FirstCry PlayBees app is perfect for our kids screen time. It has educational games, popular nursery rhymes, baby lullabies, fun activities to learn, read, trace English alphabet and numbers. Kids learn a lot!`,
    externalUrl: `https://www.instagram.com/mommyyyym/`,
    tagName: `@mommyyyym`,
    userPic: `/images/reviews/mary_magdalene_mengullo.jpg`,
  },
  {
    userName: `Phervil Tenorio`,
    place: `Philippines`,
    rating: 4,
    review: `My little one loves popping balloons game and singing the nursery rhymes. It is of really big help for us parents to have an app with loads of activities. Thanks to PlayBees, the kids are busy & we can have some time!`,
    externalUrl: `https://www.instagram.com/p/CQ00lC1h_tn/`,
    tagName: `@sassyphervil`,
    userPic: `/images/reviews/Phervil-Tenorio.jpg`,
  },
  {
    userName: `Carmen`,
    place: `Singapore`,
    rating: 4,
    review: `My favourite thing about the app is it's kids safe & ads free, I don't have to worry about Bumui getting scared or redirected to unwanted tabs. She uses 4-6 years section & plays puzzle games & finger family song!`,
    externalUrl: `https://www.instagram.com/p/CPxSp9tjV6E/`,
    tagName: `@singapore.babydiary`,
    userPic: `/images/reviews/Carmen.jpg`,
  },
  {
    userName: `Unis Nadar`,
    place: `India`,
    rating: 4,
    review: `They have educational stories & flip books. My baby loved the Egg cracking game and Balloon bursting game a lot. I was happy to see her enjoy & learn at the same time. She loves rhyme sand enjoys baby songs!`,
    externalUrl: `https://www.instagram.com/p/CQA8H-Bgxm4/`,
    tagName: `@babying_around`,
    userPic: `/images/reviews/Unis-Nadar.jpg`,
  },
  {
    userName: `Mary Janzhel Madriaga`,
    place: `Philippines`,
    rating: 5,
    review: `Play-based learning is done through guided play. It keeps motivates kids and allows them to enjoy while learning. PlayBees allows kids to learn ABCs & numbers in a fun way with games, nursery rhymes and stories`,
    externalUrl: `https://www.instagram.com/p/CTMQA45Bs66/`,
    tagName: `@jillandzailey`,
    userPic: `/images/reviews/mary_janzhel_madriaga.jpg`,
  },
  {
    userName: `Kanika Bhatia`,
    place: `India`,
    rating: 4,
    review: `Playbees has Educational games, Nursery Rhymes, Classic Stories, Read aloud books and Flip books. Sargun is enjoying while learning and we love watching her learn. You can download & get the same for your kids.`,
    externalUrl: `https://www.instagram.com/p/CRZGEUPFfQ8/`,
    tagName: `@sarguntales`,
    userPic: `/images/reviews/kanika_bhatia.jpg`,
  },
  {
    userName: `Geraldine Exconde`,
    place: `Philippines`,
    rating: 5,
    review: `Cyan's favourites include ABCs, numbers, painting, matching fruits, vegetables, listening stories via Audio Books. One of the best ways to teach kids ABC's & 123 numbers is through fun educational games!`,
    externalUrl: `https://www.instagram.com/p/CRGFIpfhn8m/`,
    tagName: `@dailymommagayieeeeee`,
    userPic: `/images/reviews/Geraldine-L.-Exconde.jpg`,
  },
  {
    userName: `Manisha Ramlani`,
    place: `India`,
    rating: 5,
    review: `We encourage Tarush to learn from all the mediums. Recently I came across FirstCry PlayBees that is fun learning app for kids. It has Rhymes, Stories, Alphabet tracing, word formation, colours, animals & more.`,
    externalUrl: `https://www.instagram.com/p/CPFw0nzHbnX/`,
    tagName: `@manisharamlani`,
    userPic: `/images/reviews/Manisha-Ramlani.jpg`,
  },
  {
    userName: `Alexandra Gamayao`,
    place: `Philippines`,
    rating: 4,
    review: `I love how Gab really focuses and enjoy playing drums, feeding animals, listening to their nursery rhymes especially his favourite baby shark song. Your kid will surely enjoy using this as It is 1000+ learning games!`,
    externalUrl: `https://www.instagram.com/p/CREBnDQh42a/`,
    tagName: `@imonicaaaa`,
    userPic: `/images/reviews/Alexandra-Monica-Gamayao.jpg`,
  },
  {
    userName: `Apoorva Mehta`,
    place: `India`,
    rating: 5,
    review: `Meera loves to dance on the tunes after she completes each game. Fruit matching game is her favourite. It has parental controls like swipe-close and maths based locking system which I personally found very unique!`,
    externalUrl: `https://www.instagram.com/p/CPnh6KZD7_y/`,
    tagName: `@meesasisters`,
    userPic: `/images/reviews/Apoorva-Mehta.jpg`,
  },
  {
    userName: `Aralei Ampongan`,
    place: `Philippines`,
    rating: 5,
    review: `Nyjah enjoys popping balloon and Easter eggs. When she finishes the game, she starts dancing to the congratulatory song. The app is easy to navigate, divided as per category age wise which makes it easy to use.`,
    externalUrl: `https://www.instagram.com/p/CQ7vRanBEOW/`,
    tagName: `@momma.ara`,
    userPic: `/images/reviews/Aralei-C.-Ampongan.jpg`,
  },
  {
    userName: `Diya A Ravlani`,
    place: `India`,
    rating: 5,
    review: `Kids are gaining knowledge through e-learning and so we allow them to have screen time. PlayBees does a good job with 1000+ fun activities, games, stories, rhymes that help Kids in early learning!`,
    externalUrl: `https://www.instagram.com/p/CPVkro_l0Fn/`,
    tagName: `@diyaravlani`,
    userPic: `/images/reviews/Diya-Ravlani.jpg`,
  },
  {
    userName: `Hazel Pereira`,
    place: `India`,
    rating: 5,
    review: `Covid pandemic has got many of us into homeschooling our kids. It has helped my child learn abcs, numbers, animals through fun games. My little one is learning new words everyday, thanks to PlayBees!`,
    externalUrl: `https://www.instagram.com/p/CPdKRT1FxKm/`,
    tagName: `@aura_and_mom`,
    userPic: `/images/reviews/Hazel-Pereira.jpg`,
  },
  {
    userName: `Vibha Shinde`,
    place: `India`,
    rating: 5,
    review: `It can be accessed on multiple devices and the developers add lots of content every week. They have read aloud fairy tales and moral stories for my rockstar. The flip books in the app give a feeling of real books!`,
    externalUrl: `https://thatchirpygirl.wordpress.com/2021/05/30/bye-bye-screen/`,
    tagName: `@thehasmukhgirl's Blog`,
    userPic: `/images/reviews/Vibha-Shinde.jpg`,
  },
  {
    userName: `Faith Briar`,
    place: `United Kindom`,
    rating: 4,
    review: `PlayBees is great for academic growth, development, motor function. The odds are endless in ways your child can express through learning! Koltan is a big lover of songs and he navigates the app on his own!`,
    externalUrl: `https://www.instagram.com/p/CPGudX5Aqs3/`,
    tagName: `@faith.briar`,
    userPic: `/images/reviews/Faith-Briar.jpg`,
  },
  {
    userName: `Sarita Yadav`,
    place: `India`,
    rating: 4,
    review: `PlayBees is a complete package that has rhymes, songs, games, stories. My daughter is 3.5 years old and loves rhymes so much that she sings along with the app - it has become her favourite learning app!`,
    externalUrl: `https://www.instagram.com/p/CP0CXxjFqE3/`,
    tagName: `@itsmedesimom`,
    userPic: `/images/reviews/Sarita-Yadav.jpg`,
  },
  {
    userName: `Nilam gajjar`,
    place: `India`,
    rating: 4,
    review: `PlayBees has age wise content divided on the basis of difficulty levels. It has math - counting, addition, subtraction. They have popular moral story books, I use those as bedtime story reader for my little one!`,
    externalUrl: `https://www.instagram.com/p/CP5-G6zJf4C/`,
    tagName: `@mom_of_2_milestones`,
    userPic: `/images/reviews/Nilam-Gajjar.jpg`,
  },
  {
    userName: `Disha Vidhani`,
    place: `India`,
    rating: 4,
    review: `Navya has learned her first words like fruits, veggies, animals. She loves to sing "buckle my shoes" and loves the tunes. She is amazed with the bright characters in PlayBees. I love to see her learn in a fun way.`,
    externalUrl: `https://www.instagram.com/tv/CPoDV9gn_9y/`,
    tagName: `@navyavidhani`,
    userPic: `/images/reviews/Disha-Vidhani.jpg`,
  },
  {
    userName: `Karen Soriano`,
    place: `Philippines`,
    rating: 5,
    review: `Jaecob & Jairus are having fun with: Pop the balloons, learn animals, Addition, Number picture puzzle, Colour the Animals, Crack the Easter egg (crack egg to see what number/ letter is inside), nursery rhymes!`,
    externalUrl: `https://www.instagram.com/p/CRBbbSUhqbx/`,
    tagName: `@raisinggentlebarakos`,
    userPic: `/images/reviews/Karen-Soriano.jpg`,
  },
  {
    userName: `Holly Brown`,
    place: `United Kindom`,
    rating: 5,
    review: `This is a fantastic app for screen time that supports child development. Avery loves shape matching puzzles & matching the object/word. It helps him in understanding words, its association & boosts his creativity!`,
    externalUrl: `https://www.instagram.com/p/CRnknhcMNih/`,
    tagName: `@blendedopinions`,
    userPic: `/images/reviews/default_user_2.jpg`,
  },
  {
    userName: `Alexies J Carreon`,
    place: `Philippines`,
    rating: 5,
    review: `Amanda enjoys colouring & painting time. She likes playing advance puzzles, matching games, brain games. She has started reading fairy tales and stories. She is improving her math skills with counting games!`,
    externalUrl: `https://www.instagram.com/p/CRYDZwbL-iA/`,
    tagName: `@mommy_alexies`,
    userPic: `/images/reviews/default_user_3.jpg`,
  },
  {
    userName: `Ruchi Tibrewal`,
    place: `India`,
    rating: 4,
    review: `Firstcry Playbees is perfect for both of us. Shubh's favourite is character building, brain games. Honestly, I don't have any objection to his screen time as it is boosting his brain growth & giving me my mee time.`,
    externalUrl: `https://www.instagram.com/p/CQFwUusJbiI/`,
    tagName: `@mrs_tibrewal`,
    userPic: `/images/reviews/default_user_4.jpg`,
  },
  {
    userName: `Juliana Appiah`,
    place: `Canada`,
    rating: 5,
    review: `Nandi has read 10+ stories this weekend and absolutely enjoys the addition, subtraction, math puzzle games. There’s no way to survive a lock-down without screen time. Turn your kid's screen time into learing time!`,
    externalUrl: `https://www.instagram.com/p/CPiXt0TFtcY/`,
    tagName: `@ammy_wiafe`,
    userPic: `/images/reviews/default_user_2.jpg`,
  },
  {
    userName: `Jenn Decker`,
    place: `United States`,
    rating: 5,
    review: `It has a section called character building that discusses bullying, lying, using manners, how to handle hurt feelings - all to teach kids about good habits. I am impressed with it, best app to have for summer travel`,
    externalUrl: `https://www.instagram.com/p/CP3j0vBjk7T/`,
    tagName: `@kid.friendly.fun`,
    userPic: `/images/reviews/default_user_4.jpg`,
  },
  {
    userName: `Jathniele Dique`,
    place: `Philippines`,
    rating: 5,
    review: `Oakley likes colours & animals. She has started identifying them. Every time she opens PlayBees, she'll ask to use one of them. All smiles & giggles every time she finishes painting or matching animals games.`,
    externalUrl: `https://www.instagram.com/p/CRLcW2gHpHT/`,
    tagName: `@mummajahph`,
    userPic: `/images/reviews/default_user_3.jpg`,
  },
  {
    userName: `Shruti Sinha`,
    place: `India`,
    rating: 5,
    review: `Kids can color alphabets, numbers, fruits, veggies, shapes. It helps to improve their hand-eye coordination. Graphics are really attractive, eye catchy and music is soothing. My little toddler loves PlayBees!`,
    externalUrl: `https://www.instagram.com/p/CQGM31vl4QX/`,
    tagName: `@mumma_betu`,
    userPic: `/images/reviews/default_user_4.jpg`,
  },
  {
    userName: `Priya Davis`,
    place: `United Kindom`,
    rating: 4,
    review: `PlayBees Kids Educational App came as a pleasant surprise. Loving the different features such as interactive story telling, fun games to help with maths, colours, reading and many more. Well done and thumbs up`,
    externalUrl: `https://www.instagram.com/p/CO78cG8gh5T/`,
    tagName: `@thehappymumco`,
    userPic: `/images/reviews/default_user_2.jpg`,
  },
  {
    userName: `Angela`,
    place: `Canada`,
    rating: 5,
    review: `PlayBees has games, stories, puzzles, numbers, fairy tales, brain games, matching games, books, shapes and colours! So it will grow with Arlo as he gets older. It is a great app for little ones to learn with games!`,
    externalUrl: `https://www.instagram.com/p/CQ7-mLcstt1/`,
    tagName: `@arlo__and__i`,
    userPic: `/images/reviews/default_user_3.jpg`,
  },
  {
    userName: `Famella Orcine`,
    place: `Philippines`,
    rating: 4,
    review: `PlayBees is an early-learning educational app for preschoolers. This app helps develop different skills in a fun way. It has games, activities, songs, stories, rhymes to learn shapes, ABCs, 123, colouring and more.`,
    externalUrl: `https://www.instagram.com/p/CTeok_Ph8kX/`,
    tagName: `@famellasue`,
    userPic: `/images/reviews/default_user_4.jpg`,
  },
  {
    userName: `Jennie L Villanueva`,
    place: `Philippines`,
    rating: 4,
    review: `My son enjoys his colouring & playing time. It has storybooks that my kids love listening to. My son is improving his math solving skills. The app can be used by kids age 0 to 6 years old. Highly Recommended!`,
    externalUrl: `https://www.instagram.com/p/CQ-57NcBU_K/`,
    tagName: `@foreverthreee`,
    userPic: `/images/reviews/default_user_3.jpg`,
  },
  {
    userName: `Carlletta Money`,
    place: `UK`,
    rating: 5,
    review: `Jax absolutely loves the feed the animals game, he’s learnt how to feed the animal and has started to count with the app! It’s amazing how quickly he’s picking up. I just love how it’s fun and educational for him!`,
    externalUrl: `https://www.instagram.com/p/CTkg8zTMHUA/`,
    tagName: `@mamatojax`,
    userPic: `/images/reviews/Carlletta_Money.jpg`,
  },
  {
    userName: `Gie Basa`,
    place: `Philippines`,
    rating: 5,
    review: `Mido's favourite is Shapes and Colors. At her age, she is fascinated and curious about different shapes and colors. Balloon pops and shapes comes out! This app helps her in early learning as it is super interactive.`,
    externalUrl: `https://www.instagram.com/p/CTjsibchh8k/`,
    tagName: `@giebarrionbasa`,
    userPic: `/images/reviews/Gie_Basa.jpg`,
  },
  {
    userName: `Mary Beth Bautista`,
    place: `Philippines`,
    rating: 5,
    review: `Mavie and I love nursery rhymes & baby songs. Every time I play it on my gadget she is very attentive and interested to the app sounds and tunes. I highly recommend PlayBees educational app for kids early learning!`,
    externalUrl: `https://www.instagram.com/p/CTmtNgtPec_/`,
    tagName: `@mommy_m_and_mavie`,
    userPic: `/images/reviews/Mary_Beth_Bautista.jpg`,
  },
  {
    userName: `Carole Enriquez`,
    place: `Philippines`,
    rating: 5,
    review: `Ryme's favourite learning games are Pop The Fruits & Veggies, Color the Shape, Fix The Animal Shape. He is fascinated with the Uppercase & Lowercase alphabet in the rocket ship. A perfect addition to our bonding time!`,
    externalUrl: `https://www.instagram.com/p/CTvo-0lhmpt/`,
    tagName: `@ohhhhcarole`,
    userPic: `/images/reviews/Carole_Enriquez.jpg`,
  },
  {
    userName: `Jane Domingo`,
    place: `Philippines`,
    rating: 5,
    review: `The app is super interactive and Sky was easily able to navigate it on his own. His favorite activities are Painting, Dino World, Planets and Numbers. The app is working very well and Sky enjoys learning with games.`,
    externalUrl: `https://www.instagram.com/p/CT6CSaoh0c0/`,
    tagName: `@mrsangel_sky`,
    userPic: `/images/reviews/Jane_Domingo.jpg`,
  },
  {
    userName: `Kristel Irene Baluden`,
    place: `Philippines`,
    rating: 5,
    review: `I love this app because of its features. Rein likes puzzles, painting, story books and nursery rhymes. The app has moral stories to teach kids values & character building. Every night I read a different story for Rein!`,
    externalUrl: `https://www.instagram.com/p/CTmM4jeBAwq/`,
    tagName: `@kristelbaluden`,
    userPic: `/images/reviews/Kristel_Irene Baluden.jpg`,
  },
  {
    userName: `Jomarie Reyes`,
    place: `Philippines`,
    rating: 5,
    review: `Sabian loves watching and listening to nursery rhymes. Her favorite is the ‘10 little dinosaurs’ which make her fall in love even more with it when she watched it from this app. The graphics & music is so beautiful!`,
    externalUrl: `https://www.instagram.com/p/CTl2oT0h35X/`,
    tagName: `@jhompetzz`,
    userPic: `/images/reviews/Jomarie_Reyes.jpg`,
  },
  {
    userName: `Coleen Faye L Manio`,
    place: `Philippines`,
    rating: 5,
    review: `Liam is having so much fun playing the piano, drums, and guitar. Unlike watching alone, the app is perfectly guiding kids like Liam how to pronounce words well since their age is beginning to widen their vocabulary.`,
    externalUrl: `https://www.instagram.com/p/CT6qSTihoYS/`,
    tagName: `@coleenfayeofficial`,
    userPic: `/images/reviews/Coleen_Faye_L Manio.jpg`,
  },
  {
    userName: `Jenette Refamonte Naval`,
    place: `Philippines`,
    rating: 5,
    review: `It's engaging & interactive. Plus, it's multi-level! The contents are designed to specific ages and individual interests like my kids. There are exciting fun word games and brain teasers best for my 5-year-old Jorel`,
    externalUrl: `https://www.instagram.com/p/CTzCqkiBQV_/`,
    tagName: `@teachermommahjen`,
    userPic: `/images/reviews/Jenette_Refamonte_Naval.jpg`,
  },
  {
    userName: `Jewelry Joy D'Souza`,
    place: `Philippines`,
    rating: 5,
    review: `For a busy mom like me, I need an app to keep my kids busy while they learn. The app has engaging activities like puzzles, numbers, coloring and painting. These games & interactive stories make learning fun for us.`,
    externalUrl: `https://www.instagram.com/p/CUXBxsTBceY/`,
    tagName: `@jewel_d.souza`,
    userPic: `/images/reviews/Jewelry_Joy_DSouza.jpg`,
  },
  {
    userName: `Sharen Joy G. Nipay`,
    place: `Philippines`,
    rating: 5,
    review: `PlayBees educational app is great for children to build and work on existing skills while keeping the fun in learning. From colors and numbers to rhymes and letters, your child can begin learning in a fun, new way.`,
    externalUrl: `https://www.instagram.com/p/CUh56cfhdzm/`,
    tagName: `@sharennipay`,
    userPic: `/images/reviews/Sharen_Joy_G_Nipay.jpg`,
  },
  {
    userName: `Rhea Marie Ortiz`,
    place: `Philippines`,
    rating: 5,
    review: `I introduced PlayBees to him and he loves it, he enjoys games & likes the story section. Scofield started counting on his own when he listens to 123 songs. His favourite are Head Shoulder Knees & Toes, and Finger Song!`,
    externalUrl: `https://www.instagram.com/p/CUfKgQilUVJ/`,
    tagName: `@scofield.mom`,
    userPic: `/images/reviews/Rhea_Marie_Ortiz.jpg`,
  },
  {
    userName: `Marie Beth Lana`,
    place: `Philippines`,
    rating: 5,
    review: `After a month of using the app my child's ability to repeat words has improved a lot. I couldn't be happier seeing him enjoy learning & playing his favorite games like "Count the Fruits and Veggies" & "Feed the Animals".`,
    externalUrl: `https://www.instagram.com/p/CThULIahO5a/`,
    tagName: `@momshiebethmarie`,
    userPic: `/images/reviews/Marie_Beth_Lana.jpg`,
  },
  {
    userName: `Pang Sin Man`,
    place: `Philippines`,
    rating: 5,
    review: `I can say it is a new kids educational app that helps toddlers & preschool kids like Sʜᴇʟʙʏ to learn their ABCs and numbers. They can learn first Words through fun games and stories. It offers popular nursery songs too!`,
    externalUrl: `https://www.instagram.com/p/CUWpwrcJu2A/`,
    tagName: `@shelby_and_mom`,
    userPic: `/images/reviews/Pang_Sin_Man.jpg`,
  },
  {
    userName: `Caseyrheen Dapin`,
    place: `Philippines`,
    rating: 5,
    review: `Tried character-building section with Lukas. I feel teaching kids- moral values, good qualities and manners is important from an early age and doing it in a creative way is a win win thing. Superb app for kids age 0-6!`,
    externalUrl: `https://www.instagram.com/p/CT_rNhxBWTO/`,
    tagName: `@keysiloves`,
    userPic: `/images/reviews/Caseyrheen_Dapin.jpg`,
  },
  {
    userName: `Cristelle Adtoon`,
    place: `Philippines`,
    rating: 5,
    review: `Lately, Ramona has been into lullabies and rhymes. With PlayBees, we can conveniently listen to them anytime! Since she is a growing toddler, this app has helped me teach her several things such as the numbers and ABCs!`,
    externalUrl: `https://www.instagram.com/p/CUebmq8hmZB/`,
    tagName: `@omgkhitel`,
    userPic: `/images/reviews/Cristelle_Adtoon.jpg`,
  },
  {
    userName: `Jennyvive Talicol`,
    place: `Philippines`,
    rating: 5,
    review: `Zoe loves Fairy Tales section of the app and reads along the narrator. This is helping her in developing her pronunciation & usage of words. In fact she would use lines from the stories in our day to day conversations.`,
    externalUrl: `https://www.instagram.com/p/CUOZevChgIS/`,
    tagName: `@jenndapin`,
    userPic: `/images/reviews/Jennyvive_Talicol.jpg`,
  },
  {
    userName: `Saffron Wheatland`,
    place: `UK`,
    rating: 5,
    review: `Lottie has loved listening to nursery rhymes and using the flip books! She loved learning the names of the animals on the balloon popping app. PlayBees is so easy for Lottie to use! Which is what I love about it!`,
    externalUrl: `https://www.instagram.com/p/CUhhOJZswao/`,
    tagName: `@life_with_lottie_x`,
    userPic: `/images/reviews/Saffron_Wheatland.jpg`,
  },
];

// {
//     userName: ``,
//     place: ``,
//     rating: 5,
//     review: ``,
//     externalUrl: ``,
//     tagName: ``,
//     userPic: ``,
//   },
