import { Container, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./../CommonStyle.css";
import DownloadSection from "./../DownloadSection";
import { sendEvent } from "./../../Config/AnalyticsConfig";
import { Helmet } from "react-helmet";
import {
  style_pageTitle,
  style_pageTitle_mobile,
  style_contactUsText,
} from "../CommonStyle";

const ContactUs = () => {
  const isMobile = window.innerWidth <= 960;
  useEffect(() => {
    sendEvent(`contact_us_page_load`);
  }, []);

  return (
    <Container className="page-parent" sx={{ color: "#5e5c90" }}>
      <Helmet>
        <title>
          FirstCry PlayBees | Nursery Rhymes, Stories, Games For Kids
        </title>
        <meta
          name="keywords"
          content="educational, baby songs, baby games, kids games, toddler learning games, story book, read stories, puzzle, memory games, preschool, kindergarten, nursery rhymes"
        />
        <meta
          name="description"
          content="Preschool educational app for kids that has 1000+ toddler learning games, baby songs, nursery rhymes and kindergarten story books for reading children!"
        />
        <meta name="author" content="FirstCry PlayBees" />
      </Helmet>
      <Typography
        variant="h3"
        gutterBottom
        sx={isMobile ? style_pageTitle_mobile : style_pageTitle}
      >
        Contact Us
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        align="center"
        sx={style_contactUsText}
      >
        Brainbees Solutions Private Limited
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        align="center"
        sx={style_contactUsText}
      >
        114, Rajashree Business Park Nr. Sohrab Hall,
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        align="center"
        sx={style_contactUsText}
      >
        338, Tadiwala Rd, Sangamvadi, Pune, Maharashtra 411001
      </Typography>
      <Typography
        variant="body2"
        gutterBottom
        align="center"
        sx={style_contactUsText}
      >
        Email:
        <a
          href="mailto:playbees@firstcry.com"
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            sendEvent(`contact_emailid_click`);
          }}
        >
          <u>playbees@firstcry.com</u>
        </a>
      </Typography>

      <DownloadSection />
    </Container>
  );
};

export default ContactUs;
